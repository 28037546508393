import {createSlice} from '@reduxjs/toolkit'

export interface ProfileSlice {
  message: string
}

const initialState: ProfileSlice = {
  message: '',
}

const saleStateSlice = createSlice({
  name: 'profileState',
  initialState,
  reducers: {
    addProfile: (state, action) => {
      state.message = action.payload.message + ' ' + new Date().getTime()
    },
    updateProfile: (state, action) => {
      state.message = action.payload.message + ' ' + new Date().getTime()
    },

    deleteProfile: (state, action) => {
      state.message = action.payload.message + ' ' + new Date().getTime()
    },
  },
})

export const {addProfile, updateProfile, deleteProfile} = saleStateSlice.actions

export default saleStateSlice.reducer
