import axios from 'axios'

const BaseURL = process.env.REACT_APP_BASE_URL

export async function getCustomers(token: string) {
  try {
    const response = await axios.get(`${BaseURL}/customer/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    console.log(response.data)

    return response.data
  } catch (error) {
    throw error
  }
}
