/* eslint-disable jsx-a11y/anchor-is-valid */

import {useEffect, useMemo} from 'react'
import {Outlet, Link, useParams} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const fullLayoutPaths = ['forgot-password', 'reset-password', 'new-password', 'confirm-otp']

const AuthLayout = () => {
  const params = useParams()

  const showFullPage = useMemo(() => {
    return fullLayoutPaths.includes(params['*']!)
  }, [params])

  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-root'>
      <div className='d-flex flex-column flex-lg-row flex-column-fluid'>
        <div className='d-flex flex-column flex-cloumn-fluid flex-center w-lg-50 p-10'>
          <div className='d-flex justify-content-between flex-column-fluid flex-column w-100 mw-450px'>
            <Outlet />
          </div>
        </div>
        <div
          className='d-none d-lg-flex flex-lg-row-fluid w-50 bgi-size-cover bgi-position-y-center bgi-position-x-start bgi-no-repeat'
          style={{backgroundImage: `url(${toAbsoluteUrl('/media/auth/bg11-mod.png')})`}}
        ></div>
        {/* {showFullPage ? null : (
        )} */}
      </div>
    </div>
  )
}

export {AuthLayout}
