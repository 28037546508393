import React from 'react'

import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import clsx from 'clsx'

const SearchBar = () => {
  return (
    <div className='app-navbar flex-shrink-0 align-items-center ms-md-4 me-md-4'>
      {/* <div className={clsx('app-navbar-item d-block d-md-none')}>
        <div
          className={clsx(
            'cursor-pointer btn btn-icon btn-custom btn-color-gray btn-active-light-primary'
          )}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-start'
        >
          <KTIcon iconName='magnifier' className='mx-1 fs-2 text-1 bg-body' />
        </div>
        <div
          className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold px-2 fs-6 w-75'
          data-kt-menu='true'
        >
          <form
            data-kt-search-element='form'
            className='w-md-100 position-relative'
            autoComplete='off'
          >
            <input
              type='text'
              className='form-control form-control-flush ps-10'
              name='search'
              placeholder='Search...'
              data-kt-search-element='input'
            />

            <span
              className='position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-1'
              data-kt-search-element='spinner'
            >
              <span className='spinner-border h-15px w-15px align-middle text-gray-400' />
            </span>

            <span
              className='btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 d-none'
              data-kt-search-element='clear'
            >
              <KTIcon iconName='cross' className='fs-2 text-lg-1 me-0' />
            </span>
          </form>
        </div>
      </div>

      <form
        data-kt-search-element='form'
        className='w-md-100 position-relative mb-lg-3 d-md-block d-none'
        autoComplete='off'
      >
        <KTIcon
          iconName='magnifier'
          className='fs-2 text-lg-1 text-gray-500 position-absolute top-50 translate-middle-y ms-3'
        />

        <input
          type='text'
          className='form-control form-control-solid ps-16'
          name='search'
          placeholder='Search...'
          data-kt-search-element='input'
        />

        <span
          className='position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-1'
          data-kt-search-element='spinner'
        >
          <span className='spinner-border h-15px w-15px align-middle text-gray-400' />
        </span>

        <span
          className='btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 d-none'
          data-kt-search-element='clear'
        >
          <KTIcon iconName='cross' className='fs-2 text-lg-1 me-0' />
        </span>
      </form> */}
    </div>
  )
}

export default SearchBar
