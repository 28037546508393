import React, {FC, useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import usePopup from '../../../../hooks/usePopup'
import EditPermissionModal from '../PermissionsTable/EditPermissionModal'
import {Menu} from '@headlessui/react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {renderBadge} from '../../../../helpers'

type Props = {
  className: string
  title: string
  subtitle: string
  data: any[]
}

const PaymentsDueTable: FC<Props> = ({className, title, subtitle, data}) => {
  const TableActionsCell: FC<{item: any}> = ({item}) => {
    const [trigger] = usePopup()

    const deleteItem = () => {
      trigger(
        'Delete',
        () => console.log('confirm'),
        () => console.log('reject')
      )
    }

    return (
      <>
        <Menu>
          {({open}) => (
            <>
              <Menu.Button
                className='btn btn-light btn-active-light-primary btn-sm text-nowrap'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
              >
                Actions
                <KTIcon iconName='down' className='fs-5 m-0' />
              </Menu.Button>
              <Menu.Items
                className={clsx(
                  'menu menu-sub d-flex menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4',
                  open && 'show'
                )}
                style={{
                  position: 'absolute',
                  zIndex: 105,
                }}
              >
                <Menu.Item as='div' className='menu-item px-3'>
                  <Link to='#' className='menu-link px-3'>
                    View
                  </Link>
                </Menu.Item>
                <Menu.Item as='div' className='menu-item px-3'>
                  <Link to='#' className='menu-link px-3'>
                    Edit
                  </Link>
                </Menu.Item>
              </Menu.Items>
            </>
          )}
        </Menu>
      </>
    )
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>

          <span className='text-muted fw-semibold fs-7'>{subtitle}</span>
        </h3>

        <div className='card-toolbar' data-kt-buttons='true'>
          <a
            className='px-4 me-1 btn btn-icon btn-sm btn-active-light-primary'
            id='kt_charts_widget_4_year_btn'
          >
            {/* <KTIcon iconName='dots-square' className='fs-1' /> */}
          </a>
        </div>
      </div>
      <div className='card-body'>
        <div className='table-responsive'>
          <table className='table table-row-dashed align-middle gs-0 gy-4 my-0'>
            <thead>
              <tr className='fs-7 fw-bold text-gray-500 border-bottom-0'>
                <th className='ps-0 w-50px norwap'>Invoice No</th>

                <th className='pe-0 text-end min-w-100px'>Amount</th>
                <th className='pe-0 text-end min-w-100px'>Paid</th>
                <th className='pe-0 text-end min-w-100px'>Due</th>
                <th className='pe-0 text-end min-w-100px'>status</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, i) => (
                <tr key={i}>
                  <td className='ps-0'>
                    <span className='text-gray-800 fw-bold mb-1 fs-6 text-start pe-0'>
                      {item.invoiceNo}
                    </span>
                  </td>

                  <td className='text-end pe-0'>
                    <span className='text-gray-800 fw-bold d-block fs-6'>{item.ammount}</span>
                  </td>
                  <td className='text-end pe-0'>
                    <span className='text-gray-800 fw-bold d-block fs-6'>{item.paid}</span>
                  </td>
                  <td className='text-end pe-0'>
                    <span className='text-gray-800 fw-bold d-block fs-6'>{item.due}</span>
                  </td>
                  <td className='text-end pe-0'>
                    <span className={clsx('badge', renderBadge(item.status))}>{item.status}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className='d-flex w-100 justify-content-center my-2'>
          {title === 'Sales Payment Due' ? (
            <>
              <Link
                to='/sales/sales-list'
                className='btn fw-bold btn-sm active btn-active-light-primary'
              >
                <span className=''>See More</span>
              </Link>
            </>
          ) : (
            <>
              <Link
                to='/purchases/purchases-list'
                className='btn fw-bold btn-sm active btn-active-light-primary'
              >
                <span className=''>See More</span>
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default PaymentsDueTable
