export const renderBadge = (status: string) => {
  let _badge: JSX.Element | string = ''

  switch (status.toLowerCase()) {
    case 'approved':
    case 'active':
    case 'activated':
    case 'refunded':
    case 'true':
    case 'paid':
    case 'recieved':
    case 'sent':
    case 'delivered':
    case 'completed':
      _badge = 'badge badge-light-success'
      break
    case 'pending':
    case 'false':
    case 'expiring':
    case 'not recieved':
      _badge = 'badge badge-light-warning'
      break
    case 'rejected':
    case 'suspended':
    case 'deactivated':
    case 'expired':
    case 'cancelled':
    case 'returned':
    case 'denied':
    case 'unpaid':
    case 'uncompleted':
    case 'over due':
      _badge = 'badge badge-light-danger'
      break
    case 'in progress':
    case 'processing':
      _badge = 'badge badge-light-info'
      break

    default:
      _badge = 'badge badge-light-warning'
      break
  }

  return _badge
}

export function numberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
