/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useRef} from 'react'
import {useThemeMode} from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {KTIcon} from '../../../_metronic/helpers/components/KTIcon'
import {getCSSVariableValue} from '../../../_metronic/assets/ts/_utils/DomHelpers'
import clsx from 'clsx'
import {numberWithCommas} from '../../../helpers'
import {useAuth} from '../../../app/modules/auth'

type Earning = {
  type: string
  amount: string
}

type Props = {
  earnings: Earning[]
  className: string
  chartSize?: number
  chartLine?: number
  chartRotate?: number
}

// util for generating chart colors based on the theme. will put into separate file if there's any reusable use cases
// Anupama - 10/13
function getChartColor(i: number | string) {
  let _clr = 'success'

  switch (i.toString()) {
    case '0':
      _clr = 'danger'
      break
    case '1':
      _clr = 'success'
      break
    case '2':
      _clr = 'gray-300'
      break
    case '3':
      _clr = 'warning'
      break
    case '4':
      _clr = 'primary'
      break

    default:
      _clr = 'gray-300'
      break
  }

  return _clr
}

function getTotal(array: number[]) {
  return array.reduce((a, b) => a + b, 0)
}

function convertToNumber(str: string) {
  return str ? Number(str.split(',').join('')) : 0
}

const TotalEarningsChart: FC<Props> = ({
  earnings,
  className,
  chartSize = 70,
  chartLine = 11,
  chartRotate = 145,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  useEffect(() => {
    refreshChart()
  }, [mode])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }
    initChart(earnings, chartSize, chartLine, chartRotate)
  }

  useEffect(() => {
    initChart(earnings, chartSize, chartLine, chartRotate)
  }, [earnings])

  const {currentUser} = useAuth()
  const currencySymbol = currentUser?.currencySymbol || '$'
  return (
    <div className={`card card-flush ${className}`}>
      <div className='card-header pt-5'>
        <div className='card-title d-flex flex-column'>
          <div className='d-flex align-items-center'>
            <span className='fs-4 fw-semibold text-gray-400 me-1 align-self-start'>
              {currencySymbol}
            </span>

            <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>
              {numberWithCommas(
                getTotal(earnings.map((earning) => convertToNumber(earning.amount)))
              )}
            </span>

            <span className='badge badge-light-success fs-base'></span>
          </div>
          <span className='text-gray-400 pt-1 fw-semibold fs-6'>Expected Earnings</span>
        </div>
      </div>

      <div className='card-body pt-2 pb-4 d-flex flex-wrap align-items-center justify-content-between'>
        <div className='d-flex flex-center me-5 pt-2'>
          <div
            id='kt_card_widget_17_chart'
            ref={chartRef}
            style={{minWidth: chartSize + 'px', minHeight: chartSize + 'px'}}
            data-kt-size={chartSize}
            data-kt-line={chartLine}
          ></div>
        </div>

        <div className='d-flex flex-column align-items-start'>
          {earnings.map((earning, i) => (
            <div
              className='d-flex w-100 fw-semibold align-items-center justify-content-between'
              key={i}
            >
              <div className='d-flex align-items-center justify-content start'>
                <div
                  className={clsx('bullet w-20px h-3px rounded-2 me-3', 'bg-' + getChartColor(i))}
                ></div>
                <div className='text-gray-500 me-4'>{earning.type}</div>
              </div>

              <div className='fw-bolder text-gray-700 text-end'>
                {currencySymbol}
                {numberWithCommas(earning.amount as unknown as number)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const initChart = function (
  earnings: Earning[],
  chartSize: number = 70,
  chartLine: number = 11,
  chartRotate: number = 145
) {
  const el = document.getElementById('kt_card_widget_17_chart')
  if (!el) {
    return
  }
  el.innerHTML = ''

  var options = {
    size: chartSize,
    lineWidth: chartLine,
    rotate: chartRotate,
    //percent:  el.getAttribute('data-kt-percent') ,
  }

  const canvas = document.createElement('canvas')
  const span = document.createElement('span')

  // @ts-ignore
  if (typeof G_vmlCanvasManager !== 'undefined') {
    // @ts-ignore
    G_vmlCanvasManager.initElement(canvas)
  }

  const ctx = canvas.getContext('2d')
  canvas.width = canvas.height = options.size

  el.appendChild(span)
  el.appendChild(canvas)

  // @ts-ignore
  ctx.translate(options.size / 2, options.size / 2) // change center
  // @ts-ignore
  ctx.rotate((-1 / 2 + options.rotate / 180) * Math.PI) // rotate -90 deg

  //imd = ctx.getImageData(0, 0, 240, 240);
  const radius = (options.size - options.lineWidth) / 2

  const drawCircle = function (color: string, lineWidth: number, percent: number) {
    percent = Math.min(Math.max(0, percent || 1), 1)
    // console.log(percent, color)
    if (!ctx) {
      return
    }

    ctx.beginPath()
    ctx.arc(0, 0, radius, 0, Math.PI * 2 * percent, false)
    ctx.strokeStyle = color
    ctx.lineCap = 'round' // butt, round or square
    ctx.lineWidth = lineWidth
    ctx.stroke()
  }

  // Init 2

  let currentAmount = 0
  let _earnings: number[] = []

  // converting earnings array to _earnings array which we can use to draw the chart.
  // Anupama - 10/13

  for (let i = 0; i < earnings.length; i++) {
    const item = earnings[i]
    const total = convertToNumber(item?.amount) + currentAmount

    _earnings.push(total)

    currentAmount = total
  }

  for (let i = earnings.length - 1; i >= 0; i--) {
    const item = _earnings[i]
    drawCircle(
      getCSSVariableValue('--bs-' + getChartColor(i)),
      options.lineWidth,
      item / getTotal(earnings.map((earning) => convertToNumber(earning.amount)))
    )
  }
  // drawCircle('#E4E6EF', options.lineWidth, 100 / 100)

  // drawCircle(getCSSVariableValue('--bs-success'), options.lineWidth, 100 / 250)
}

export {TotalEarningsChart}
