import axios from 'axios'

const BaseURL = process.env.REACT_APP_BASE_URL

export async function fivemonthSales(token: string) {
  try {
    const response = await axios.get(`${BaseURL}/dashboard/past5monthsales`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response.data
  } catch (error) {
    throw error
  }
}
