import React, {FC} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'

type Props = {
  className: string
  title: string
  subtitle: string
  data: any
}

const TopSellingProducts: FC<Props> = ({className, title, subtitle, data}) => {
  const {currentUser} = useAuth()
  const currencySymbol = currentUser?.currencySymbol || '$'

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>

          <span className='text-muted fw-semibold fs-7'>{subtitle}</span>
        </h3>

        <div className='card-toolbar' data-kt-buttons='true'>
          <Link
            to='/products/products-list'
            className='btn btn-sm btn-color-muted btn-active active btn-active-light-primary px-4 me-1'
          >
            View All
          </Link>
        </div>
      </div>
      <div className='card-body'>
        <div className='table-responsive'>
          <table className='table table-row-dashed align-middle gs-0 gy-4 my-0'>
            <thead>
              <tr className='fs-7 fw-bold text-gray-500 border-bottom-0'>
                <th className='ps-0 w-50px'>ITEM</th>
                <th className='min-w-225px' />

                <th className='pe-0 text-end min-w-100px'>TOTAL PRICE</th>
              </tr>
            </thead>
            <tbody>
              {data.map(
                (
                  item: {
                    _id:
                      | boolean
                      | React.ReactChild
                      | React.ReactFragment
                      | React.ReactPortal
                      | null
                      | undefined
                    productImage: string
                    productName:
                      | boolean
                      | React.ReactChild
                      | React.ReactFragment
                      | React.ReactPortal
                      | null
                      | undefined
                    totalQtySold:
                      | boolean
                      | React.ReactChild
                      | React.ReactFragment
                      | React.ReactPortal
                      | null
                      | undefined
                    totalPrice: number
                  },
                  i: React.Key | null | undefined
                ) => (
                  <tr key={i}>
                    <td>
                      <img src={item.productImage} className='w-50px' alt='' />
                    </td>
                    <td className='ps-0'>
                      <a className='text-gray-800 fw-bold text-hover-primary mb-1 fs-6 text-start pe-0'>
                        {item.productName}
                      </a>
                      <span className='text-gray-400 fw-semibold fs-7 d-block text-start ps-0'>
                        {item._id}
                      </span>
                    </td>

                    <td className='text-end pe-0'>
                      <span className='text-gray-800 fw-bold d-block fs-6'>
                        {currencySymbol} {item.totalQtySold}
                      </span>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default TopSellingProducts
