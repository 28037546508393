import axios from 'axios'
import {UserModel} from '../app/modules/auth/core/_models'
const BaseURL = process.env.REACT_APP_BASE_URL

export async function getUserByToken(token: string) {
  try {
    const response = await axios.post<UserModel>(`${BaseURL}/auth/verify-auth-token`, {
      token,
    })

    return response.data
  } catch (error) {
    throw error
  }
}
