/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import * as am5 from '@amcharts/amcharts5'
import {QueryState} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import PageHeader from '../../../components/shared/PageHeader'
import {TotalEarningsChart} from '../../../components/dashboard/CustomCharts/TotalEarningsChart'
import Feed from '../../../components/dashboard/CustomWidgets/Feed'
import {StatsWidget} from '../../../components/dashboard/CustomWidgets/Stats'
import {AreaChart} from '../../../components/dashboard/CustomCharts/AreaChart'
import BarLineChart from '../../../components/dashboard/CustomCharts/BarLineChart'
import {getCSSVariableValue} from '../../../_metronic/assets/ts/_utils'
import TopSellingProducts from '../../../components/dashboard/CustomTable/TopSellingProductsTable'
import TopSellingCategoriesChart from '../../../components/dashboard/CustomCharts/TopSellingCategoriesChart'
import PaymentsDueTable from '../../../components/dashboard/CustomTable/PaymentsDueTable'
import TopClients from '../../../components/dashboard/CustomTable/TopClientsTable'
import {ChartData, Data, dashboardModel} from '../../../_models/dashboardModel'
import {useAuth} from '../../modules/auth'
import {fivemonthSales} from '../../../api/get/fiveMonthSales'
import {topSellingItems} from '../../../api/get/topSellingItems'
import {toast} from 'react-toastify'
import {getDashboardData} from '../../../api/get/dashboardData'
import {getCustomers} from '../../../api/get/getCustomers'
import moment from 'moment'
import {useSelector} from 'react-redux'
import {RootState} from '../../../redux/reduxStore'
import axios from 'axios'

const state: QueryState = {
  page: 1,
  items_per_page: 10,
}

const HomePage: FC = () => {
  const BaseUrl = process.env.REACT_APP_BASE_URL
  const [isLoading, setLoading] = useState(false)
  const [data2, setData2] = useState<dashboardModel>()
  const [data3, setData3] = useState<any[]>([])
  const [purcheaseDue, setPurcheaseDue] = useState<any[]>([])
  const [revenu, setrevenu] = useState<ChartData>()
  const [topSelling, setTopSelling] = useState<any[]>([])
  const {currentUser} = useAuth()
  const [data4, setData4] = useState([])

  const onChange = useSelector((state: RootState) => state.sale)
  const [expectedEarning, setExpectedEarning] = useState<any>([])
  const [catStat, setcatStat] = useState<any>([])

  const [categoriesList, setCategoriesList] = useState<any>([])

  const [totalProfits, setTotalProfits] = useState<any>('')

  const getProfits = async () => {
    console.log('totalprofit v1', BaseUrl)
    await axios
      .get(`${BaseUrl}/statictics/totalProfits`, {
        headers: {
          Authorization: `Bearer ${currentUser?.api_token}`,
        },
      })
      .then((response) => {
        setTotalProfits(response.data.data)
        console.log('totalprofit v2', response)
      })
  }

  useEffect(() => {
    getProfits()
  }, [])

  const initializingData = async () => {
    await axios
      .get(`${BaseUrl}/dashboard/expectet/${currentUser?.id}`, {
        headers: {
          Authorization: `Bearer ${currentUser?.api_token}`,
        },
      })
      .then(
        (response) => {
          console.log('expected', response.data)
          setExpectedEarning(response.data.expectedEarnings)
          setcatStat(response.data.sampleCat)
          setCategoriesList(response.data.categoriesList)

          console.log('expected', categoriesList)
        },
        (error) => {
          console.log(error)
        }
      )

    fivemonthSales(currentUser?.api_token as string).then((result: any) => {
      console.log(result)
      setrevenu(result)
    })

    topSellingItems(currentUser?.api_token as string, currentUser?.id as any).then(
      (result: any) => {
        console.log('topSelleing', result)
        setTopSelling(result)
      }
    )

    getDashboardData(currentUser?.api_token as string, currentUser?.id as any).then(
      (result: any) => {
        const sales = result || []
        console.log('sales', sales)
        setData2(sales)
        setData3(sales.salesDueData)
        setPurcheaseDue(sales.purcheaseDue)
      }
    )
    getCustomers(currentUser?.api_token as string)
      .then((result: any) => {
        const sales = result.customers || []
        setData4(sales)
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
      })
  }

  useEffect(() => {
    setLoading(true)

    initializingData()
      .then(() => {})
      .catch((e) => {})
      .finally(() => {
        setLoading(false)
      })
  }, [onChange])

  const currentTime = moment().format('H')

  let title = ''
  const hour = parseInt(currentTime, 10)

  if (hour >= 5 && hour < 12) {
    title = 'Good morning!'
  } else if (hour >= 12 && hour < 18) {
    title = 'Good afternoon!'
  } else {
    title = 'Good evening!'
  }

  const lastFiveYears = data2?.lastFiveYearsOldestFirst || []

  const series = lastFiveYears.map((yearData) => {
    return {
      year: yearData.year as number,
      income: yearData.grandTotal as number,
      expenses: 0,
    }
  })

  const currencySymbol = currentUser?.currencySymbol || '$'

  console.log(data2)

  return (
    <>
      {isLoading ? (
        <>
          <div className='loading-container'>
            <div className='spinner-border text-primary' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='row'>
            <h1>
              {title} {currentUser?.fullname}
            </h1>
            <p>Here's what's happening with your store</p>
          </div>
          <div className='row g-3 gx-xl-7 mb-5 mb-xl-10'>
            <div className='col-md-6 col-lg-6 col-xl-6 md-mb-3 mb-md-7'>
              <TotalEarningsChart className='mb-3 mb-xl-7 h-md-100' earnings={expectedEarning} />
            </div>
            <div className='col-md-6 col-lg-6 col-xl-6 md-mb-3 mb-md-7'>
              <div className='row g-3 gx-xl-7 mb-3 mb-xl-7'>
                <div className='col-sm-6 mb-3 mb-md-0'>
                  <Feed title='total profit' value={`${totalProfits || 0}`} />
                </div>
                <div className='col-sm-6 mb-0'>
                  <Feed title='sales due' value={`${data2?.totalDue || 0}`} />
                </div>
              </div>
              <div className='row gx-3 gx-xl-7'>
                <div className='col-sm-6 mb-3 mb-md-0'>
                  <Feed title='total purchase' value={`${data2?.totalpurches || 0}`} />
                </div>
                <div className='col-sm-6 mb-0'>
                  <Feed title='purchase due' value={`${data2?.totalsales || 0}`} />
                </div>
              </div>
            </div>
            <div className='col-xxl-12'>
              <div className='row g-3 gx-xl-7'>
                <div className='col-md-6 col-xl-3 mb-3 mb-lg-3 mb-xl-0'>
                  <StatsWidget
                    className='text-white'
                    color='success'
                    svgIcon='profile-user'
                    iconColor='gray-100'
                    title={`${data2?.totalCustomers || 0}`}
                    description='Total Customers'
                  />
                </div>
                <div className='col-md-6 col-xl-3 mb-3 mb-lg-3 mb-xl-0'>
                  <StatsWidget
                    className='text-white'
                    color='success'
                    svgIcon='delivery-2'
                    iconColor='gray-100'
                    title={`${data2?.totalVendors || 0}`}
                    description='Total Vendors'
                  />
                </div>
                <div className='col-md-6 col-xl-3 mb-3 mb-md-3 mb-xl-0'>
                  <StatsWidget
                    className='text-white'
                    color='info'
                    svgIcon='notepad-bookmark'
                    iconColor='gray-100'
                    title={`${data2?.totalsales || 0}`}
                    description='Sales Invoice'
                  />
                </div>
                <div className='col-md-6 col-xl-3 mb-3 mb-md-3 mb-xl-0'>
                  <StatsWidget
                    className='text-white'
                    color='info'
                    svgIcon='abstract-20'
                    iconColor='gray-100'
                    title={`${data2?.purcheasev2Count || 0}`}
                    description='Purchase invoice'
                  />
                </div>
              </div>
              {/* <div className='row g-3 gx-xl-7'></div> */}
            </div>
            <div className='col-md-6 col-xxl-6'>
              <AreaChart
                className='card-xl-stretch mb-5 mb-xl-8'
                title='Sales in Last 30 Days'
                subtitle=''
                series={[
                  {
                    name: revenu?.name,
                    data: data2?.lastFiveMonthsGrandTotals as number[],
                  },
                ]}
              />
            </div>
            <div className='col-md-6 col-xxl-6'>
              <BarLineChart
                className='card-xl-stretch mb-5 mb-xl-8'
                title='Sales in Current Financial Year'
                description=''
                series={series}
              />
            </div>
            <div className='col-md-6 col-xxl-6'>
              <TopSellingProducts
                className='card-xl-stretch mb-5 mb-xl-8'
                title='Top Selling Product'
                subtitle={`Total ${topSelling.length}`}
                data={topSelling}
              />
            </div>
            <div className='col-md-6 col-xxl-6'>
              <TopSellingCategoriesChart
                className='card-xl-stretch mb-5 mb-xl-8'
                title='Top Selling Categories'
                subtitle={`Total ${categoriesList.length}`}
                categories={categoriesList}
                series={[
                  {
                    name: '',
                    data: catStat,
                  },
                ]}
              />
            </div>
            <div className='col-md-6 col-xxl-6'>
              <PaymentsDueTable
                className='card-xl-stretch mb-5 mb-xl-8'
                title='Sales Payment Due'
                subtitle=''
                data={data3}
              />
            </div>
            <div className='col-md-6 col-xxl-6'>
              <PaymentsDueTable
                className='card-xl-stretch mb-5 mb-xl-8'
                title='Purchase Payment Due'
                subtitle=''
                data={purcheaseDue}
              />
            </div>
            <div className='col-md-12'>
              <TopClients isLoading={false} isHome data={data4} />
            </div>
          </div>
        </>
      )}
    </>
  )
}

const Home: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <HomePage />
    </>
  )
}

export default Home
