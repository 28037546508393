import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import LanguageSelector from '../../../../components/shared/LanguageSelector'

const initialValues = {
  email: 'admin@demo.com',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function ActivationSuccess() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      console.log(values)
    },
  })

  return (
    <>
      <div className='d-flex flex-stack py-2'>
        <div className='me-2'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/pbv.png')} className='h-100px mb-6' />
        </div>
        <div className=''></div>
      </div>

      <div className='py-20'>
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_password_reset_form'
          onSubmit={formik.handleSubmit}
        >
          <div className='text-start mb-11'>
            <h1 className='text-dark fw-bolder mb-3 fs-3x'>Congratulations!</h1>
            <div className='text-gray-500 fw-semibold fs-6'>
              Your account has been activated with verification code and email.
            </div>
          </div>

          <div className='pb-lg-0 d-flex flex-start gap-4'>
            <Link to='/auth/login'>
              <button
                type='button'
                id='kt_login_password_reset_form_cancel_button'
                className='btn btn-success'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                Continue to Log in
              </button>
            </Link>{' '}
          </div>
        </form>
      </div>
      <div />
    </>
  )
}
