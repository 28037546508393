import {createSlice} from '@reduxjs/toolkit'

export interface PermissionSlice {
  permissions: {}
}

const initialState: PermissionSlice = {
  permissions: {},
}

const permissionStateSlice = createSlice({
  name: 'permissionState',
  initialState,
  reducers: {
    setPermissions(state, action) {
      state.permissions = action.payload
    },
  },
})

export const {setPermissions} = permissionStateSlice.actions

export default permissionStateSlice.reducer

export const selectPermissions = (state) => state.permissions.permissions
