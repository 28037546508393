import axios from 'axios'
const BaseURL = process.env.REACT_APP_BASE_URL

export async function login(usernameOrEmail: string, password: string) {
  try {
    const response = await axios.post(`${BaseURL}/auth/login`, {
      usernameOrEmail,
      password,
    })

    return response.data
  } catch (error) {
    throw error
  }
}
