import {FC, useEffect, useRef} from 'react'
import * as am5 from '@amcharts/amcharts5'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import * as am5xy from '@amcharts/amcharts5/xy'
import {getCSSVariableValue} from '../../../_metronic/assets/ts/_utils/DomHelpers'

type Props = {
  className?: string
  title: string
  description: string
  series: {
    year: number
    income: number
    expenses: number
    strokeSettings?: any
    columnSettings?: any
    info?: any
  }[]
}

const BarLineChart: FC<Props> = ({className, title, description, series}) => {
  useEffect(() => {
    const root = am5.Root.new('barLineChart')

    root.setThemes([am5themes_Animated.new(root)])

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: 'panX',
        wheelY: 'zoomX',
        layout: root.verticalLayout,
      })
    )

    chart.set('paddingBottom', 0)
    chart.set('paddingTop', 0)
    chart.set('paddingRight', 0)
    chart.set('paddingLeft', 0)

    chart.set('cursor', am5xy.XYCursor.new(root, {}))

    var xRenderer = am5xy.AxisRendererX.new(root, {})
    var xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: 'year',
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      })
    )
    const isDarkMode = getCSSVariableValue('--bs-theme-dark') === 'dark' ? true : false

    xAxis.get('renderer').labels.template.setAll({
      fill: am5.color(getCSSVariableValue(isDarkMode ? '--bs-light' : '--bs-dark')),
    })

    // xRenderer.grid.template.setAll({
    //   location: 1,
    // })

    xAxis.data.setAll(series)

    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        extraMax: 0.1,
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 0,
        }),
      })
    )
    yAxis.get('renderer').grid.template.set('forceHidden', true)
    xAxis.get('renderer').grid.template.set('forceHidden', true)

    var series1 = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: 'Income',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'income',
        categoryXField: 'year',
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: 'horizontal',
          labelText: '{name} in {categoryX}: {valueY} {info}',
        }),
        fill: am5.color(getCSSVariableValue('--bs-primary')),
      })
    )

    yAxis.get('renderer').labels.template.setAll({
      fill: am5.color(getCSSVariableValue(isDarkMode ? '--bs-light' : '--bs-dark')),
    })

    series1.columns.template.setAll({
      tooltipY: am5.percent(10),
      templateField: 'columnSettings',
      cornerRadiusTL: 6,
      cornerRadiusTR: 6,
    })

    series1.data.setAll(series)

    var series2 = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: 'Expenses',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'expenses',
        categoryXField: 'year',
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: 'horizontal',
          labelText: '{name} in {categoryX}: {valueY} {info}',
        }),
        stroke: am5.color(getCSSVariableValue('--bs-success')),
      })
    )

    series2.strokes.template.setAll({
      strokeWidth: 2,
      templateField: 'strokeSettings',
    })

    series2.data.setAll(series)

    series2.bullets.push(function () {
      return am5.Bullet.new(root, {
        sprite: am5.Circle.new(root, {
          strokeWidth: 3,
          stroke: series2.get('stroke'),
          radius: 5,
          fill: root.interfaceColors.get('background'),
        }),
      })
    })

    chart.set('cursor', am5xy.XYCursor.new(root, {}))

    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    var legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
      })
    )
    legend.data.setAll(chart.series.values)

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart.appear(1000, 200)
    series1.appear()

    return () => root && root.dispose()
  }, [series])

  return (
    <div className={`card ${className}`}>
      <div className='card-body d-flex flex-column p-0'>
        <div className='flex-grow-1 card-p pb-0 mb-4'>
          <div className='d-flex flex-stack flex-wrap'>
            <div className='me-2'>
              <span className='text-dark text-hover-primary fw-bold fs-3'>{title}</span>

              <div className='text-muted fs-7 fw-semibold'>{description}</div>
            </div>

            {/* <div className={`fw-bold fs-3 text-${chartColor}`}>${amount}</div> */}
          </div>
          <div
            id='barLineChart'
            className='card-rounded-bottom'
            style={{width: '100%', height: '350px'}}
          />
        </div>
      </div>
    </div>
  )
}

export default BarLineChart
