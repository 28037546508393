import axios from 'axios'

const BaseURL = process.env.REACT_APP_BASE_URL

export async function register(
  ref: string,
  email: string,
  credential: string,
  username: string,
  fullname: string,
  phone: string,
  country: string,
  address: string
) {
  try {
    const response = await axios.post(`${BaseURL}/auth/register`, {
      ref,
      email,
      credential,
      username,
      fullname,
      phone,
      country,
      address,
    })

    return response.data
  } catch (error) {
    throw error
  }
}
