import axios from 'axios'

const BaseURL = process.env.REACT_APP_BASE_URL

export async function topSellingItems(token: string, id: string) {
  try {
    const response = await axios.get(`${BaseURL}/dashboard/topselling/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response.data
  } catch (error) {
    throw error
  }
}
