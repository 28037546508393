import {Link} from 'react-router-dom'
import {useAuth} from '../../../app/modules/auth'
import {Languages} from '../../../_metronic/partials/layout/header-menus/Languages'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {useDispatch} from 'react-redux'
import {useEffect, useState} from 'react'
import axios from 'axios'
import {setPermissions} from '../../../redux/slicers/permissionSlice'

const UserMenu = () => {
  const {currentUser, logout} = useAuth()
  const baseUrl = process.env.REACT_APP_BASE_URL
  const dispatch = useDispatch()
  const [permission, setPermission] = useState<any>([])

  useEffect(() => {
    const getuserPermission = async () => {
      await axios
        .post(
          `${baseUrl}/clientrole/getrolebymerchant/${currentUser?.id}`,
          {
            role: currentUser?.role,
          },
          {
            headers: {
              Authorization: `Bearer ${currentUser?.api_token}`,
            },
          }
        )
        .then((res) => {
          setPermission(res.data.roles[0].permissions)
          dispatch(setPermissions(res.data.roles[0].permissions))
          console.log(permission)
        })
    }
    getuserPermission()
  }, [])

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src={currentUser?.profilePicture} />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.username}
              {/* <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span> */}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to={'/profile'} className='menu-link px-5'>
          My Profile
        </Link>
      </div>
      {!currentUser?.merchentid && (
        <>
          {(permission?.subscriptionmanagement?.read ||
            permission?.subscriptionmanagement?.create ||
            permission?.subscriptionmanagement?.delete ||
            permission?.subscriptionmanagement?.update ||
            currentUser?.role === 'client-store-owner') && (
            <div className='menu-item px-5'>
              <Link to={'/settings/plan-settings'} className='menu-link px-5'>
                My Subscriptions
              </Link>
            </div>
          )}
          {(permission?.subscriptionmanagement?.read ||
            permission?.subscriptionmanagement?.create ||
            permission?.subscriptionmanagement?.delete ||
            permission?.subscriptionmanagement?.update ||
            currentUser?.role === 'client-store-owner') && (
            <div className='menu-item px-5'>
              <Link to={'/settings/payment-settings'} className='menu-link px-5'>
                Payment Settings
              </Link>
            </div>
          )}
        </>
      )}

      <div className='menu-item px-5 my-1'>
        <Link to='/profile/edit' className='menu-link px-5'>
          Account Settings
        </Link>
      </div>

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export default UserMenu
