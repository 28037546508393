import ApexCharts, {ApexOptions} from 'apexcharts'
import clsx from 'clsx'
import {FC, useEffect, useRef} from 'react'
import {getCSS, getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'
import {useThemeMode} from '../../../../_metronic/partials'
import {numberWithCommas} from '../../../../helpers'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'

type Props = {
  className?: string
  title: string
  subtitle: string
  categories: string[]
  series: ApexOptions['series']
}

const TopSellingCategoriesChart: FC<Props> = ({className, title, subtitle, series, categories}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)

  const {mode} = useThemeMode()
  const {currentUser} = useAuth()
  const currencySymbol = currentUser?.currencySymbol || '$'

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(height, series, categories, currencySymbol)
    )
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, series])

  return (
    <div className={clsx('card card-flush h-md-100', className)}>
      <div className='card-header flex-nowrap pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>

          <span className='text-muted fw-semibold fs-7'>{subtitle}</span>
        </h3>
        <div className='card-toolbar'>
          <Link
            to='/products/category-list'
            className='btn btn-sm btn-color-muted btn-active active btn-active-light-primary px-4 me-1'
          >
            View All
          </Link>
        </div>
      </div>
      <div className='card-body pt-5 ps-6'>
        <div ref={chartRef} id='kt_charts_widget_4_chart' style={{height: '350px'}}></div>
      </div>
    </div>
  )
}

function getChartOptions(
  height: number,
  series: ApexOptions['series'],
  categories: string[],
  currencySymbol: string
): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-800')
  const borderColor = getCSSVariableValue('--bs-gray-200')

  const baseColor = getCSSVariableValue('--bs-success')
  const baseLightColor = getCSSVariableValue('--bs-success-light')
  const secondaryColor = getCSSVariableValue('--bs-warning')
  const secondaryLightColor = getCSSVariableValue('--bs-warning-light')

  const colors = [
    getCSSVariableValue('--bs-primary'),
    getCSSVariableValue('--bs-danger'),
    getCSSVariableValue('--bs-success'),
    getCSSVariableValue('--bs-warning'),
    getCSSVariableValue('--bs-info'),
  ]

  return {
    series,
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    colors,
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: 'bottom',
        },
        barHeight: '50%',
        borderRadius: 10,
        distributed: true,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      textAnchor: 'middle',
      offsetX: 20,
      style: {
        fontSize: '12px',
      },
      formatter: (val, opt) => currencySymbol + numberWithCommas(val as number),
    },
    fill: {
      opacity: 1,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      categories: categories,

      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: true,
      },
      labels: {
        show: false,
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: labelColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
          fontWeight: 800,
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return {currencySymbol} + numberWithCommas(val)
        },
      },
    },
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}

export default TopSellingCategoriesChart
