import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'

import Home from '../pages/dashboard/Home'

// import ViewEditSale from '../pages/dashboard/Sales/components/SalesTable/vieweditsale'

const PrivateRoutes = () => {
  // const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))

  //custom pages
  const ProfilePage = lazy(() => import('../pages/profile/Profile'))
  const EditProfilePage = lazy(() => import('../pages/profile/EditProfile'))
  const EditBusinessProfilePage = lazy(() => import('../pages/profile/EditBusinessProfile'))
  const NotificationsPage = lazy(() => import('../pages/profile/Notifications'))

  // sales
  const SalesPage = lazy(() => import('../pages/dashboard/Sales'))
  const Viewsales = lazy(() => import('../pages/dashboard/Sales/components/SalesTable/viewsales'))
  const CreateSalesPage = lazy(() => import('../pages/dashboard/Sales/CreateSale'))
  const InvoicesPage = lazy(() => import('../pages/dashboard/Sales/Invoices'))
  const SalesReturnsPage = lazy(() => import('../pages/dashboard/Sales/SalesReturns'))
  const CreateSalesReturn = lazy(() => import('../pages/dashboard/Sales/CreateSalesReturn'))
  const QuotationsPage = lazy(() => import('../pages/dashboard/Sales/Quotations'))
  const AddQuotationsPage = lazy(() => import('../pages/dashboard/Sales/AddQuotation'))
  const CreateInvoicePage = lazy(() => import('../pages/dashboard/Sales/CreateInvoice'))
  const PosSales = lazy(() => import('../pages/dashboard/Sales/pos-orders'))
  const Viewinvoice = lazy(
    () => import('../pages/dashboard/Sales/components/InvoicesTable/viewinvoice')
  )
  const ViewSaleReturn = lazy(
    () => import('../pages/dashboard/Sales/components/SalesReturnsTable/viewsalereturn')
  )
  const ViewQuotation = lazy(
    () => import('../pages/dashboard/Sales/components/QuotationsTable/vieweditquoatation')
  )

  const ViewEditSale = lazy(
    () => import('../pages/dashboard/Sales/components/SalesTable/vieweditsale')
  )

  const ViewInVoice2 = lazy(() => import('../pages/dashboard/Sales/viewInoice'))

  const CreateIncoiceForSale = lazy(
    () => import('../pages/dashboard/Sales/components/SalesTable/createInvoiceForSale')
  )

  // inventory
  const InventoryPage = lazy(() => import('../pages/dashboard/Inventory'))
  const RunStockAnalysisPage = lazy(() => import('../pages/dashboard/Inventory/RunStockAnalysis'))
  const AddNewItemPage = lazy(() => import('../pages/dashboard/Inventory/AddProduct'))
  const EditItemPage = lazy(() => import('../pages/dashboard/Inventory/EditProduct'))
  const InventoryValuationPage = lazy(
    () => import('../pages/dashboard/Inventory/InventoryValuation')
  )
  const ViewInventoryItemPage = lazy(() => import('../pages/dashboard/Inventory/ViewInventoryItem'))
  const EditServiceForm = lazy(
    () => import('../pages/dashboard/Inventory/components/AddServiceForm/editService')
  )
  //expenses
  const ExpensesListPage = lazy(() => import('../pages/dashboard/Expenses'))
  const PurchaseOrderListPage = lazy(() => import('../pages/dashboard/Expenses/PurchaseOrderList'))
  const BillsPage = lazy(() => import('../pages/dashboard/Expenses/ExpenseBills'))
  const AddPurchaseOrderPage = lazy(() => import('../pages/dashboard/Expenses/AddPurchaseOrder'))
  const AddBillPage = lazy(() => import('../pages/dashboard/Expenses/AddBill'))
  const AddExpensePage = lazy(() => import('../pages/dashboard/Expenses/AddExpense'))
  const UpdatePurcheaseOrder = lazy(
    () => import('../pages/dashboard/Expenses/components/PurchaseOrdersTable/editPurchease')
  )
  const UpdateBill = lazy(
    () => import('../pages/dashboard/Expenses/components/ExpenseBillsTable/editBill')
  )
  const UpdateExpences = lazy(
    () => import('../pages/dashboard/Expenses/components/ExpensesTable/editExpences')
  )
  const ViewInvoicePdf = lazy(() => import('../pages/dashboard/Expenses/viewInvoiceModel'))

  // contacts
  const ContactsPage = lazy(() => import('../pages/dashboard/Contacts/index'))
  const ViewCustomerPage = lazy(() => import('../pages/dashboard/Contacts/ViewCustomer'))
  const EditCustomerPage = lazy(() => import('../pages/dashboard/Contacts/EditCustomer'))
  const VendorsPage = lazy(() => import('../pages/dashboard/Contacts/Vendors'))
  const ViewVendorPage = lazy(() => import('../pages/dashboard/Contacts/ViewVendor'))
  const EditVendorPage = lazy(() => import('../pages/dashboard/Contacts/EditVendor'))
  const Supllier = lazy(() => import('../pages/dashboard/Contacts/Supplier'))
  const AddSupllier = lazy(
    () => import('../pages/dashboard/Contacts/components/supplier/AddSupplier')
  )
  const ViewEditSupplier = lazy(
    () => import('../pages/dashboard/Contacts/components/supplier/vieweditcustomer')
  )
  const AddCustomer = lazy(() => import('../pages/dashboard/Contacts/components/AddCustomer'))
  const AddVendor = lazy(() => import('../pages/dashboard/Contacts/Addvendor'))
  const ViewSupplier = lazy(
    () => import('../pages/dashboard/Contacts/components/supplier/viewsupplier')
  )
  const EditSupplier = lazy(
    () => import('../pages/dashboard/Contacts/components/supplier/editSupplier')
  )

  //sales reports
  const SalesReportsPage = lazy(() => import('../pages/dashboard/Reports/index'))
  const PurchaseReportsPage = lazy(() => import('../pages/dashboard/Reports/PurchaseReports'))
  const InventoryReportsPage = lazy(() => import('../pages/dashboard/Reports/InventoryReports'))
  const InvoiceReportsPage = lazy(() => import('../pages/dashboard/Reports/InvoiceReports'))
  const CustomerReportsPage = lazy(() => import('../pages/dashboard/Reports/CustomerReports'))
  const SupplierReportsPage = lazy(() => import('../pages/dashboard/Reports/SupplierReports'))
  const ViewQuoatation = lazy(
    () => import('../pages/dashboard/Sales/components/QuotationsTable/ViewQuoatation')
  )
  // Purchases
  const PurchaseListPage = lazy(() => import('../pages/dashboard/Purchases/index'))
  const CreatePurchasePage = lazy(() => import('../pages/dashboard/Purchases/AddPurchase'))
  const ImportPurchasePage = lazy(() => import('../pages/dashboard/Purchases/ImportPurchase'))
  const PurchaseOrdersPage = lazy(() => import('../pages/dashboard/Purchases/PurchaseOrders'))
  const PurchaseReturnsPage = lazy(() => import('../pages/dashboard/Purchases/PurchaseReturns'))
  const AddPurchaseReturnPage = lazy(() => import('../pages/dashboard/Purchases/AddPurchaseReturn'))

  const ViewEditPurcheaseReturn = lazy(
    () =>
      import('../pages/dashboard/Purchases/components/PurchaseReturnsTable/viewEditPurcheaseReturn')
  )
  const ViewPurchease = lazy(
    () => import('../pages/dashboard/Purchases/components/PurchasesTable/viewPurchease')
  )
  const EditPurchease = lazy(
    () => import('../pages/dashboard/Purchases/components/PurchasesTable/EditPurchease')
  )

  // Products
  const ProductsPage = lazy(() => import('../pages/dashboard/Products/index'))
  const AddProductPage = lazy(() => import('../pages/dashboard/Products/AddProduct'))
  const UpdateProduct = lazy(
    () => import('../pages/dashboard/Products/components/ProductsTable/editproduct')
  )

  const ViewProductPage = lazy(() => import('../pages/dashboard/Products/ViewProduct'))
  const CategoryListPage = lazy(() => import('../pages/dashboard/Products/CategoryList'))
  const AddCategoryPage = lazy(() => import('../pages/dashboard/Products/AddCategory'))
  const ViewEdit = lazy(
    () => import('../pages/dashboard/Products/components/CategoriesTable/vieEditcategory')
  )

  const BrandsPage = lazy(() => import('../pages/dashboard/Products/BrandsList'))
  const AddBrandPage = lazy(() => import('../pages/dashboard/Products/AddBrand'))
  const ViewEditBrand = lazy(
    () => import('../pages/dashboard/Products/components/BrandsTable/viewEditbrand')
  )
  const SubCategoryListPage = lazy(() => import('../pages/dashboard/Products/SubCategoryList'))
  const AddSubCategoryPage = lazy(() => import('../pages/dashboard/Products/AddSubCategory'))
  const ViewEditSubCategories = lazy(
    () => import('../pages/dashboard/Products/components/SubCategoriesTable/viewEditSubCategories')
  )
  const PrintBarcode = lazy(() => import('../pages/dashboard/Products/PrintBarcode'))
  const ImportProductPage = lazy(() => import('../pages/dashboard/Products/ImportProduct'))

  // user management
  const RolesListPage = lazy(() => import('../pages/dashboard/UserManagement/Roles/RolesList'))
  const UsersListPage = lazy(() => import('../pages/dashboard/UserManagement/Users/UserList'))
  const UserPage = lazy(() => import('../pages/dashboard/UserManagement/Users/UserProfile'))
  const PermissionsListPage = lazy(() => import('../pages/dashboard/UserManagement/Permissions'))

  //settings
  const PayemntSettingsPage = lazy(() => import('../pages/settings/PaymentSettings'))
  const PlanSettingsPage = lazy(() => import('../pages/settings/PlanSettings'))
  const ComparePlansPage = lazy(() => import('../pages/settings/ComparePlans'))
  const CheckoutPlanPage = lazy(() => import('../pages/settings/CheckoutPlan'))
  const PayPalTutorial = lazy(() => import('../pages/settings/PayPalTutorial'))
  // POS
  const POSSystem = lazy(() => import('../pages/dashboard/POS/index'))
  const UpdateHoldPos = lazy(() => import('../pages/dashboard/POS/updateHoldPos'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<Home />} />
        <Route
          path='profile'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='profile/edit'
          element={
            <SuspensedView>
              <EditProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='profile/edit-business-info/:id'
          element={
            <SuspensedView>
              <EditBusinessProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='settings/payment-settings'
          element={
            <SuspensedView>
              <PayemntSettingsPage />
            </SuspensedView>
          }
        />
        <Route
          path='settings/paypal-tutorial'
          element={
            <SuspensedView>
              <PayPalTutorial />
            </SuspensedView>
          }
        />

        <Route
          path='settings/plan-settings'
          element={
            <SuspensedView>
              <PlanSettingsPage />
            </SuspensedView>
          }
        />
        <Route
          path='settings/compare-plans'
          element={
            <SuspensedView>
              <ComparePlansPage />
            </SuspensedView>
          }
        />
        <Route
          path='settings/plan-settings/checkout'
          element={
            <SuspensedView>
              <CheckoutPlanPage />
            </SuspensedView>
          }
        />
        {/* dashboard routes */}
        <Route
          path='/notifications'
          element={
            <SuspensedView>
              <NotificationsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/sales/sales-list'
          element={
            <SuspensedView>
              <SalesPage />
            </SuspensedView>
          }
        />
        <Route
          path='/sales/pos-orders'
          element={
            <SuspensedView>
              <PosSales />
            </SuspensedView>
          }
        />
        <Route
          path='/sales/create-sale'
          element={
            <SuspensedView>
              <CreateSalesPage />
            </SuspensedView>
          }
        />
        <Route
          path='/sales/edit-sale/:id'
          element={
            <SuspensedView>
              <ViewEditSale />
            </SuspensedView>
          }
        />
        <Route
          path='/sales/edit-invoice/:id'
          element={
            <SuspensedView>
              <Viewinvoice />
            </SuspensedView>
          }
        />
        <Route
          path='/sales/view-sale-return/:id'
          element={
            <SuspensedView>
              <ViewSaleReturn isEdit={false} />
            </SuspensedView>
          }
        />
        <Route
          path='/sales/edit-sale-return/:id'
          element={
            <SuspensedView>
              <ViewSaleReturn isEdit={true} />
            </SuspensedView>
          }
        />
        {/* <Route
          path='/sales/view-quotation/:id'
          element={
            <SuspensedView>
              <ViewQuotation isEdit={false} />
            </SuspensedView>
          }
        /> */}
        <Route
          path='/sales/view-quotation/:id'
          element={
            <SuspensedView>
              <ViewQuoatation />
            </SuspensedView>
          }
        />
        <Route
          path='/sales/edit-quotation/:id'
          element={
            <SuspensedView>
              <ViewQuotation isEdit={true} />
            </SuspensedView>
          }
        />
        <Route
          path='/sales/view-sales/:id'
          element={
            <SuspensedView>
              <Viewsales />
            </SuspensedView>
          }
        />
        <Route
          path='/sales/invoices-list'
          element={
            <SuspensedView>
              <InvoicesPage />
            </SuspensedView>
          }
        />
        <Route
          path='/sales/create-invoice'
          element={
            <SuspensedView>
              <CreateInvoicePage />
            </SuspensedView>
          }
        />
        <Route
          path='/sales/returns'
          element={
            <SuspensedView>
              <SalesReturnsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/sales/create-return'
          element={
            <SuspensedView>
              <CreateSalesReturn />
            </SuspensedView>
          }
        />
        <Route
          path='/sales/quotations'
          element={
            <SuspensedView>
              <QuotationsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/sales/add-quotation'
          element={
            <SuspensedView>
              <AddQuotationsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/sales/invoice/view/:id'
          element={
            <SuspensedView>
              <ViewInVoice2 />
            </SuspensedView>
          }
        />
        <Route
          path='/sales/invoice/create/:id'
          element={
            <SuspensedView>
              <CreateIncoiceForSale />
            </SuspensedView>
          }
        />
        <Route
          path='/inventory/inventory-list'
          element={
            <SuspensedView>
              <InventoryPage />
            </SuspensedView>
          }
        />
        <Route
          path='/inventory/stock-analysis'
          element={
            <SuspensedView>
              <RunStockAnalysisPage />
            </SuspensedView>
          }
        />
        <Route
          path='/inventory/add-item'
          element={
            <SuspensedView>
              <AddNewItemPage />
            </SuspensedView>
          }
        />
        <Route
          path='/inventory/edit-item'
          element={
            <SuspensedView>
              <EditItemPage />
            </SuspensedView>
          }
        />
        <Route
          path='/inventory/valuation'
          element={
            <SuspensedView>
              <InventoryValuationPage />
            </SuspensedView>
          }
        />
        <Route
          path='/inventory/:id'
          element={
            <SuspensedView>
              <ViewInventoryItemPage />
            </SuspensedView>
          }
        />
        <Route
          path='/expenses/view-purchase-order/:id'
          element={
            <SuspensedView>
              <ViewInvoicePdf />
            </SuspensedView>
          }
        />
        <Route
          path='/expenses/expenses-list'
          element={
            <SuspensedView>
              <ExpensesListPage />
            </SuspensedView>
          }
        />
        <Route
          path='/expenses/purchase-order-list'
          element={
            <SuspensedView>
              <PurchaseOrderListPage />
            </SuspensedView>
          }
        />
        <Route
          path='/expenses/bills'
          element={
            <SuspensedView>
              <BillsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/expenses/add-purchase-order'
          element={
            <SuspensedView>
              <AddPurchaseOrderPage />
            </SuspensedView>
          }
        />
        <Route
          path='/expenses/edit-purchase-order/:id'
          element={
            <SuspensedView>
              <UpdatePurcheaseOrder isEdit={true} />
            </SuspensedView>
          }
        />
        <Route
          path='/expenses/view-purchase-order/:id'
          element={
            <SuspensedView>
              <UpdatePurcheaseOrder isEdit={false} />
            </SuspensedView>
          }
        />
        <Route
          path='/expenses/add-bill'
          element={
            <SuspensedView>
              <AddBillPage />
            </SuspensedView>
          }
        />
        <Route
          path='/expenses/edit-expenses/:id'
          element={
            <SuspensedView>
              <UpdateBill isEdit={true} />
            </SuspensedView>
          }
        />
        <Route
          path='/expenses/view-expenses/:id'
          element={
            <SuspensedView>
              <UpdateBill isEdit={false} />
            </SuspensedView>
          }
        />
        <Route
          path='/expenses/add-expense'
          element={
            <SuspensedView>
              <AddExpensePage />
            </SuspensedView>
          }
        />
        <Route
          path='/expenses/edit-expense/:id'
          element={
            <SuspensedView>
              <UpdateExpences isEdit={true} />
            </SuspensedView>
          }
        />
        <Route
          path='/expenses/view-expense/:id'
          element={
            <SuspensedView>
              <UpdateExpences isEdit={false} />
            </SuspensedView>
          }
        />
        <Route
          path='/contacts/customers-list'
          element={
            <SuspensedView>
              <ContactsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/contacts/customer/:id'
          element={
            <SuspensedView>
              <ViewCustomerPage />
            </SuspensedView>
          }
        />
        <Route
          path='/contacts/edit-customer/:id'
          element={
            <SuspensedView>
              <EditCustomerPage />
            </SuspensedView>
          }
        />
        <Route
          path='/contacts/add-customer'
          element={
            <SuspensedView>
              <AddCustomer />
            </SuspensedView>
          }
        />
        {/* <Route
          path='/contacts/add-customer'
          element={
            <SuspensedView>
              <EditCustomerPage isEdit={false} />
            </SuspensedView>
          }
        /> */}
        <Route
          path='/contacts/vendors-list'
          element={
            <SuspensedView>
              <VendorsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/contacts/vendor/:id'
          element={
            <SuspensedView>
              <ViewVendorPage />
            </SuspensedView>
          }
        />
        <Route
          path='/contacts/edit-vendor/:id'
          element={
            <SuspensedView>
              <EditVendorPage />
            </SuspensedView>
          }
        />
        <Route
          path='/contacts/add-vendor'
          element={
            <SuspensedView>
              <AddVendor />
            </SuspensedView>
          }
        />
        <Route
          path='/contacts/supplier-list'
          element={
            <SuspensedView>
              <Supllier />
            </SuspensedView>
          }
        />
        <Route
          path='/contacts/add-supplier'
          element={
            <SuspensedView>
              <AddSupllier isSidebar={false} />
            </SuspensedView>
          }
        />
        <Route
          path='/contacts/edit-supplier/:id'
          element={
            <SuspensedView>
              <EditSupplier />
            </SuspensedView>
          }
        />
        <Route
          path='/contacts/view-supplier/:id'
          element={
            <SuspensedView>
              <ViewSupplier />
            </SuspensedView>
          }
        />
        <Route
          path='/reports/sales-reports'
          element={
            <SuspensedView>
              <SalesReportsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/reports/purchase-reports'
          element={
            <SuspensedView>
              <PurchaseReportsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/reports/inventory-reports'
          element={
            <SuspensedView>
              <InventoryReportsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/reports/invoice-reports'
          element={
            <SuspensedView>
              <InvoiceReportsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/reports/customer-reports'
          element={
            <SuspensedView>
              <CustomerReportsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/reports/supplier-reports'
          element={
            <SuspensedView>
              <SupplierReportsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/purchases/purchases-list'
          element={
            <SuspensedView>
              <PurchaseListPage />
            </SuspensedView>
          }
        />
        <Route
          path='/purchases/create-purchase'
          element={
            <SuspensedView>
              <CreatePurchasePage />
            </SuspensedView>
          }
        />
        <Route
          path='/purchases/view-purchase/:id'
          element={
            <SuspensedView>
              <ViewPurchease />
            </SuspensedView>
          }
        />
        <Route
          path='/purchases/edit-purchase/:id'
          element={
            <SuspensedView>
              <EditPurchease />
            </SuspensedView>
          }
        />
        <Route
          path='/purchases/import-purchase'
          element={
            <SuspensedView>
              <ImportPurchasePage />
            </SuspensedView>
          }
        />
        <Route
          path='/purchases/purchase-orders'
          element={
            <SuspensedView>
              <PurchaseOrdersPage />
            </SuspensedView>
          }
        />
        <Route
          path='/purchases/purchase-returns'
          element={
            <SuspensedView>
              <PurchaseReturnsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/purchases/add-purchase-return'
          element={
            <SuspensedView>
              <AddPurchaseReturnPage />
            </SuspensedView>
          }
        />
        <Route
          path='/purchases/view-purchase-return/:id'
          element={
            <SuspensedView>
              <ViewEditPurcheaseReturn isEdit={false} />
            </SuspensedView>
          }
        />
        <Route
          path='/purchases/edit-purchase-return/:id'
          element={
            <SuspensedView>
              <ViewEditPurcheaseReturn isEdit={true} />
            </SuspensedView>
          }
        />
        <Route
          path='/products/products-list'
          element={
            <SuspensedView>
              <ProductsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/products/add-product'
          element={
            <SuspensedView>
              <AddProductPage />
            </SuspensedView>
          }
        />
        <Route
          path='/products/edit-product/:id'
          element={
            <SuspensedView>
              <UpdateProduct isEdit />
            </SuspensedView>
          }
        />
        <Route
          path='/products/edit-service/:id'
          element={
            <SuspensedView>
              <EditServiceForm isEdit />
            </SuspensedView>
          }
        />
        <Route
          path='/products/product/:id'
          element={
            <SuspensedView>
              <ViewProductPage />
            </SuspensedView>
          }
        />
        <Route
          path='/products/category-list'
          element={
            <SuspensedView>
              <CategoryListPage />
            </SuspensedView>
          }
        />
        <Route
          path='/products/add-category'
          element={
            <SuspensedView>
              <AddCategoryPage isSidebar={false} />
            </SuspensedView>
          }
        />
        <Route
          path='/products/view-category/:id'
          element={
            <SuspensedView>
              <ViewEdit isEdit={false} />
            </SuspensedView>
          }
        />
        <Route
          path='/products/edit-category/:id'
          element={
            <SuspensedView>
              <ViewEdit isEdit={true} />
            </SuspensedView>
          }
        />
        <Route
          path='/products/brands-list'
          element={
            <SuspensedView>
              <BrandsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/products/add-brand'
          element={
            <SuspensedView>
              <AddBrandPage />
            </SuspensedView>
          }
        />
        <Route
          path='/products/edit-brand/:id'
          element={
            <SuspensedView>
              <ViewEditBrand isEdit={false} />
            </SuspensedView>
          }
        />
        <Route
          path='/products/view-brand/:id'
          element={
            <SuspensedView>
              <ViewEditBrand isEdit={true} />
            </SuspensedView>
          }
        />
        <Route
          path='/products/sub-category-list'
          element={
            <SuspensedView>
              <SubCategoryListPage />
            </SuspensedView>
          }
        />
        <Route
          path='/products/add-sub-category'
          element={
            <SuspensedView>
              <AddSubCategoryPage />
            </SuspensedView>
          }
        />
        {/* <Route
          path='/products/edit-sub-category/:id'
          element={
            <SuspensedView>
              <AddSubCategoryPage isEdit />
            </SuspensedView>
          }
        /> */}
        <Route
          path='/products/view-sub-category/:id'
          element={
            <SuspensedView>
              <ViewEditSubCategories isEdit={false} />
            </SuspensedView>
          }
        />
        <Route
          path='/products/edit-sub-category/:id'
          element={
            <SuspensedView>
              <ViewEditSubCategories isEdit={true} />
            </SuspensedView>
          }
        />
        <Route
          path='/products/print-barcode'
          element={
            <SuspensedView>
              <PrintBarcode />
            </SuspensedView>
          }
        />
        <Route
          path='/products/import-product'
          element={
            <SuspensedView>
              <ImportProductPage />
            </SuspensedView>
          }
        />
        <Route
          path='/user-management/roles-list'
          element={
            <SuspensedView>
              <RolesListPage />
            </SuspensedView>
          }
        />
        <Route
          path='/user-management/users-list'
          element={
            <SuspensedView>
              <UsersListPage />
            </SuspensedView>
          }
        />
        <Route
          path='/user-management/permissions-list'
          element={
            <SuspensedView>
              <PermissionsListPage />
            </SuspensedView>
          }
        />
        <Route
          path='/user-management/user/:id'
          element={
            <SuspensedView>
              <UserPage />
            </SuspensedView>
          }
        />
        <Route
          path='/pos'
          element={
            <SuspensedView>
              <POSSystem />
            </SuspensedView>
          }
        />
        <Route
          path='/pos/update-hold-pos/:id'
          element={
            <SuspensedView>
              <UpdateHoldPos />
            </SuspensedView>
          }
        />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
