import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import LanguageSelector from '../../../../components/shared/LanguageSelector'
import axios from 'axios'
import {toast} from 'react-toastify'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)

  const BaseUrl = process.env.REACT_APP_BASE_URL

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        setLoading(true)
        await axios
          .post(`${BaseUrl}/auth/forgotPassword`, {
            email: values.email,
          })
          .then((response) => {
            toast.success('Sent password reset. Please check your email')
            formik.resetForm()
          })
      } catch (error: any) {
        toast.error(error.message)
        return error
      } finally {
        setLoading(false)
      }
    },
  })
  return (
    <>
      <div className='d-flex flex-stack py-2'>
        <div className='me-2'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/pbv.png')} className='h-100px mb-6' />
        </div>
        <div className=''>
          <div className='text-gray-500 text-center fw-semibold fs-6'>
            Already a member?{' '}
            <Link to='/auth/login' className='link-primary'>
              Sign In
            </Link>
          </div>
        </div>
      </div>

      <div className='py-20'>
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_password_reset_form'
          onSubmit={formik.handleSubmit}
        >
          <div className='text-start mb-11'>
            {/* <img alt='Logo' src={toAbsoluteUrl('/media/logos/pbv.png')} className='h-100px mb-6' /> */}
            <h1 className='text-dark fw-bolder mb-3 fs-3x'>Forgot password?</h1>
            <div className='text-gray-500 fw-semibold fs-6'>
              Enter your email address to reset your password.
            </div>
          </div>

          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>Email Address</label>
            <input
              type='email'
              placeholder='Enter email'
              autoComplete='off'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control form-control-solid',
                {'is-invalid': formik.touched.email && formik.errors.email},
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='pb-lg-0 d-flex flex-start gap-4'>
            <button type='submit' id='kt_password_reset_submit' className='btn btn-primary'>
              {!loading && <span className='indicator-label'>Submit</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <Link to='/auth/login' className='btn btn-light text-primary'>
              Return to Log in
            </Link>
          </div>
          {/* end::Form group */}
        </form>
      </div>
      <div />
      {/* <LanguageSelector /> */}
    </>
  )
}
