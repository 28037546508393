import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useParams} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import {toast} from 'react-toastify'
import {register} from '../../../../api/_request.register'

export function Registration() {
  const {id} = useParams()

  const [country, setCountry] = useState('')

  useEffect(() => {
    fetch('https://ipapi.co/json/')
      .then((res) => res.json())
      .then((response) => {
        console.log('Country is : ', response)
        setCountry(response.country_code)
      })
      .catch((data) => {
        console.log('Request failed:', data)
      })
  }, [])

  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }
  console.log(id)

  const [isReferralInputOpen, setReferralInputOpen] = useState(false)

  const registrationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Email is required'),
    username: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols'),
    fullname: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols'),
    country: Yup.string().min(2, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols'),
    address: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols'),
    password: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Password is required'),
    mobileNumber: Yup.string().required('Mobile Number is required'),
  })

  const initialValues = {
    email: '',
    username: '',
    fullname: '',
    country: '',
    address: '',
    password: '',
    mobileNumber: '',
    referralCode: `${id}`,
  }

  const handleKeyDown = (event) => {
    if (event.key === ' ') {
      event.preventDefault() // Prevents entering the space character
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)

      try {
        await toast
          .promise(
            register(
              values.referralCode,
              values.email,
              values.password,
              values.username,
              values.fullname,
              values.mobileNumber,
              values.country,
              values.address
            ),
            {
              pending: 'Please wait...',
              success: `${values.email} is successfully registered. Please check your email for a verification link`,
              error: `${values.email} or ${values.username} is already registered. Please login with your credentials`,
            }
          )
          .then((res) => {
            console.log(res)
            if (res.status === 200) {
              setStatus(res.data.message)
              formik.resetForm()
            } else {
              console.log(res)
              setStatus(res.data.message)
            }

            formik.resetForm()
          })
      } catch (error) {
        console.log(error)
        setStatus('An error occurred during registration')
      } finally {
        setStatus('')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <>
      <div className='d-flex flex-stack py-2'>
        <div className='me-2'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/pbv.png')} className='h-100px mb-6' />
        </div>
        <div className=''>
          <div className='text-gray-500 text-center fw-semibold fs-6'>
            Already a member?{' '}
            <Link to='/auth/login' className='link-primary'>
              Sign In
            </Link>
          </div>
        </div>
      </div>
      <div className='py-20'>
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_signup_form'
          onSubmit={formik.handleSubmit}
        >
          <div className='text-start mb-11'>
            <h1 className='text-dark fw-bolder mb-3 fs-3x'>Create an Account</h1>
            <div className='text-gray-500 fw-semibold fs-6'>
              Get unlimieted access & earn Money.
            </div>
          </div>

          {formik.status && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          )}

          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Email Address</label>
            <input
              placeholder='Email'
              type='email'
              autoComplete='off'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control form-control-solid',
                {'is-invalid': formik.touched.email && formik.errors.email},
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Username</label>
            <input
              placeholder='Username'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('username')}
              className={clsx(
                'form-control form-control-solid',
                {'is-invalid': formik.touched.username && formik.errors.username},
                {
                  'is-valid': formik.touched.username && !formik.errors.username,
                }
              )}
              onKeyDown={handleKeyDown}
            />

            {formik.touched.username && formik.errors.username && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.username}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Full Name</label>
            <input
              placeholder='Full Name'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('fullname')}
              className={clsx(
                'form-control form-control-solid',
                {'is-invalid': formik.touched.fullname && formik.errors.fullname},
                {
                  'is-valid': formik.touched.fullname && !formik.errors.fullname,
                }
              )}
            />
            {formik.touched.fullname && formik.errors.fullname && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.fullname}</span>
                </div>
              </div>
            )}
          </div>
          <div className='row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>country</label>
            <select
              aria-label='Select an country'
              data-control='select2'
              data-placeholder='Select an country...'
              className={clsx(
                'form-control form-control-solid',
                {'is-invalid': formik.touched.country && formik.errors.country},
                {'is-valid': formik.touched.country && !formik.errors.country}
              )}
              data-select2-id='select2-data-9-py72'
              tabIndex={-1}
              aria-hidden='true'
              data-kt-initialized={1}
              {...formik.getFieldProps('country')}
            >
              <option value='' data-select2-id='select2-data-11-0ljl'>
                Select a Country...
              </option>
              <option data-kt-flag='flags/afghanistan.svg' value='AF'>
                Afghanistan
              </option>
              <option data-kt-flag='flags/aland-islands.svg' value='AX'>
                Aland Islands
              </option>
              <option data-kt-flag='flags/albania.svg' value='AL'>
                Albania
              </option>
              <option data-kt-flag='flags/algeria.svg' value='DZ'>
                Algeria
              </option>
              <option data-kt-flag='flags/american-samoa.svg' value='AS'>
                American Samoa
              </option>
              <option data-kt-flag='flags/andorra.svg' value='AD'>
                Andorra
              </option>
              <option data-kt-flag='flags/angola.svg' value='AO'>
                Angola
              </option>
              <option data-kt-flag='flags/anguilla.svg' value='AI'>
                Anguilla
              </option>
              <option data-kt-flag='flags/antigua-and-barbuda.svg' value='AG'>
                Antigua and Barbuda
              </option>
              <option data-kt-flag='flags/argentina.svg' value='AR'>
                Argentina
              </option>
              <option data-kt-flag='flags/armenia.svg' value='AM'>
                Armenia
              </option>
              <option data-kt-flag='flags/aruba.svg' value='AW'>
                Aruba
              </option>
              <option data-kt-flag='flags/australia.svg' value='AU'>
                Australia
              </option>
              <option data-kt-flag='flags/austria.svg' value='AT'>
                Austria
              </option>
              <option data-kt-flag='flags/azerbaijan.svg' value='AZ'>
                Azerbaijan
              </option>
              <option data-kt-flag='flags/bahamas.svg' value='BS'>
                Bahamas
              </option>
              <option data-kt-flag='flags/bahrain.svg' value='BH'>
                Bahrain
              </option>
              <option data-kt-flag='flags/bangladesh.svg' value='BD'>
                Bangladesh
              </option>
              <option data-kt-flag='flags/barbados.svg' value='BB'>
                Barbados
              </option>
              <option data-kt-flag='flags/belarus.svg' value='BY'>
                Belarus
              </option>
              <option data-kt-flag='flags/belgium.svg' value='BE'>
                Belgium
              </option>
              <option data-kt-flag='flags/belize.svg' value='BZ'>
                Belize
              </option>
              <option data-kt-flag='flags/benin.svg' value='BJ'>
                Benin
              </option>
              <option data-kt-flag='flags/bermuda.svg' value='BM'>
                Bermuda
              </option>
              <option data-kt-flag='flags/bhutan.svg' value='BT'>
                Bhutan
              </option>
              <option data-kt-flag='flags/bolivia.svg' value='BO'>
                Bolivia, Plurinational State of
              </option>
              <option data-kt-flag='flags/bonaire.svg' value='BQ'>
                Bonaire, Sint Eustatius and Saba
              </option>
              <option data-kt-flag='flags/bosnia-and-herzegovina.svg' value='BA'>
                Bosnia and Herzegovina
              </option>
              <option data-kt-flag='flags/botswana.svg' value='BW'>
                Botswana
              </option>
              <option data-kt-flag='flags/brazil.svg' value='BR'>
                Brazil
              </option>
              <option data-kt-flag='flags/british-indian-ocean-territory.svg' value='IO'>
                British Indian Ocean Territory
              </option>
              <option data-kt-flag='flags/brunei.svg' value='BN'>
                Brunei Darussalam
              </option>
              <option data-kt-flag='flags/bulgaria.svg' value='BG'>
                Bulgaria
              </option>
              <option data-kt-flag='flags/burkina-faso.svg' value='BF'>
                Burkina Faso
              </option>
              <option data-kt-flag='flags/burundi.svg' value='BI'>
                Burundi
              </option>
              <option data-kt-flag='flags/cambodia.svg' value='KH'>
                Cambodia
              </option>
              <option data-kt-flag='flags/cameroon.svg' value='CM'>
                Cameroon
              </option>
              <option data-kt-flag='flags/canada.svg' value='CA'>
                Canada
              </option>
              <option data-kt-flag='flags/cape-verde.svg' value='CV'>
                Cape Verde
              </option>
              <option data-kt-flag='flags/cayman-islands.svg' value='KY'>
                Cayman Islands
              </option>
              <option data-kt-flag='flags/central-african-republic.svg' value='CF'>
                Central African Republic
              </option>
              <option data-kt-flag='flags/chad.svg' value='TD'>
                Chad
              </option>
              <option data-kt-flag='flags/chile.svg' value='CL'>
                Chile
              </option>
              <option data-kt-flag='flags/china.svg' value='CN'>
                China
              </option>
              <option data-kt-flag='flags/christmas-island.svg' value='CX'>
                Christmas Island
              </option>
              <option data-kt-flag='flags/cocos-island.svg' value='CC'>
                Cocos (Keeling) Islands
              </option>
              <option data-kt-flag='flags/colombia.svg' value='CO'>
                Colombia
              </option>
              <option data-kt-flag='flags/comoros.svg' value='KM'>
                Comoros
              </option>
              <option data-kt-flag='flags/cook-islands.svg' value='CK'>
                Cook Islands
              </option>
              <option data-kt-flag='flags/costa-rica.svg' value='CR'>
                Costa Rica
              </option>
              <option data-kt-flag='flags/ivory-coast.svg' value='CI'>
                Côte d'Ivoire
              </option>
              <option data-kt-flag='flags/croatia.svg' value='HR'>
                Croatia
              </option>
              <option data-kt-flag='flags/cuba.svg' value='CU'>
                Cuba
              </option>
              <option data-kt-flag='flags/curacao.svg' value='CW'>
                Curaçao
              </option>
              <option data-kt-flag='flags/czech-republic.svg' value='CZ'>
                Czech Republic
              </option>
              <option data-kt-flag='flags/denmark.svg' value='DK'>
                Denmark
              </option>
              <option data-kt-flag='flags/djibouti.svg' value='DJ'>
                Djibouti
              </option>
              <option data-kt-flag='flags/dominica.svg' value='DM'>
                Dominica
              </option>
              <option data-kt-flag='flags/dominican-republic.svg' value='DO'>
                Dominican Republic
              </option>
              <option data-kt-flag='flags/ecuador.svg' value='EC'>
                Ecuador
              </option>
              <option data-kt-flag='flags/egypt.svg' value='EG'>
                Egypt
              </option>
              <option data-kt-flag='flags/el-salvador.svg' value='SV'>
                El Salvador
              </option>
              <option data-kt-flag='flags/equatorial-guinea.svg' value='GQ'>
                Equatorial Guinea
              </option>
              <option data-kt-flag='flags/eritrea.svg' value='ER'>
                Eritrea
              </option>
              <option data-kt-flag='flags/estonia.svg' value='EE'>
                Estonia
              </option>
              <option data-kt-flag='flags/ethiopia.svg' value='ET'>
                Ethiopia
              </option>
              <option data-kt-flag='flags/falkland-islands.svg' value='FK'>
                Falkland Islands (Malvinas)
              </option>
              <option data-kt-flag='flags/fiji.svg' value='FJ'>
                Fiji
              </option>
              <option data-kt-flag='flags/finland.svg' value='FI'>
                Finland
              </option>
              <option data-kt-flag='flags/france.svg' value='FR'>
                France
              </option>
              <option data-kt-flag='flags/french-polynesia.svg' value='PF'>
                French Polynesia
              </option>
              <option data-kt-flag='flags/gabon.svg' value='GA'>
                Gabon
              </option>
              <option data-kt-flag='flags/gambia.svg' value='GM'>
                Gambia
              </option>
              <option data-kt-flag='flags/georgia.svg' value='GE'>
                Georgia
              </option>
              <option data-kt-flag='flags/germany.svg' value='DE'>
                Germany
              </option>
              <option data-kt-flag='flags/ghana.svg' value='GH'>
                Ghana
              </option>
              <option data-kt-flag='flags/gibraltar.svg' value='GI'>
                Gibraltar
              </option>
              <option data-kt-flag='flags/greece.svg' value='GR'>
                Greece
              </option>
              <option data-kt-flag='flags/greenland.svg' value='GL'>
                Greenland
              </option>
              <option data-kt-flag='flags/grenada.svg' value='GD'>
                Grenada
              </option>
              <option data-kt-flag='flags/guam.svg' value='GU'>
                Guam
              </option>
              <option data-kt-flag='flags/guatemala.svg' value='GT'>
                Guatemala
              </option>
              <option data-kt-flag='flags/guernsey.svg' value='GG'>
                Guernsey
              </option>
              <option data-kt-flag='flags/guinea.svg' value='GN'>
                Guinea
              </option>
              <option data-kt-flag='flags/guinea-bissau.svg' value='GW'>
                Guinea-Bissau
              </option>
              <option data-kt-flag='flags/haiti.svg' value='HT'>
                Haiti
              </option>
              <option data-kt-flag='flags/vatican-city.svg' value='VA'>
                Holy See (Vatican City State)
              </option>
              <option data-kt-flag='flags/honduras.svg' value='HN'>
                Honduras
              </option>
              <option data-kt-flag='flags/hong-kong.svg' value='HK'>
                Hong Kong
              </option>
              <option data-kt-flag='flags/hungary.svg' value='HU'>
                Hungary
              </option>
              <option data-kt-flag='flags/iceland.svg' value='IS'>
                Iceland
              </option>
              <option data-kt-flag='flags/india.svg' value='IN'>
                India
              </option>
              <option data-kt-flag='flags/indonesia.svg' value='ID'>
                Indonesia
              </option>
              <option data-kt-flag='flags/iran.svg' value='IR'>
                Iran, Islamic Republic of
              </option>
              <option data-kt-flag='flags/iraq.svg' value='IQ'>
                Iraq
              </option>
              <option data-kt-flag='flags/ireland.svg' value='IE'>
                Ireland
              </option>
              <option data-kt-flag='flags/isle-of-man.svg' value='IM'>
                Isle of Man
              </option>
              <option data-kt-flag='flags/israel.svg' value='IL'>
                Israel
              </option>
              <option data-kt-flag='flags/italy.svg' value='IT'>
                Italy
              </option>
              <option data-kt-flag='flags/jamaica.svg' value='JM'>
                Jamaica
              </option>
              <option data-kt-flag='flags/japan.svg' value='JP'>
                Japan
              </option>
              <option data-kt-flag='flags/jersey.svg' value='JE'>
                Jersey
              </option>
              <option data-kt-flag='flags/jordan.svg' value='JO'>
                Jordan
              </option>
              <option data-kt-flag='flags/kazakhstan.svg' value='KZ'>
                Kazakhstan
              </option>
              <option data-kt-flag='flags/kenya.svg' value='KE'>
                Kenya
              </option>
              <option data-kt-flag='flags/kiribati.svg' value='KI'>
                Kiribati
              </option>
              <option data-kt-flag='flags/north-korea.svg' value='KP'>
                Korea, Democratic People's Republic of
              </option>
              <option data-kt-flag='flags/kuwait.svg' value='KW'>
                Kuwait
              </option>
              <option data-kt-flag='flags/kyrgyzstan.svg' value='KG'>
                Kyrgyzstan
              </option>
              <option data-kt-flag='flags/laos.svg' value='LA'>
                Lao People's Democratic Republic
              </option>
              <option data-kt-flag='flags/latvia.svg' value='LV'>
                Latvia
              </option>
              <option data-kt-flag='flags/lebanon.svg' value='LB'>
                Lebanon
              </option>
              <option data-kt-flag='flags/lesotho.svg' value='LS'>
                Lesotho
              </option>
              <option data-kt-flag='flags/liberia.svg' value='LR'>
                Liberia
              </option>
              <option data-kt-flag='flags/libya.svg' value='LY'>
                Libya
              </option>
              <option data-kt-flag='flags/liechtenstein.svg' value='LI'>
                Liechtenstein
              </option>
              <option data-kt-flag='flags/lithuania.svg' value='LT'>
                Lithuania
              </option>
              <option data-kt-flag='flags/luxembourg.svg' value='LU'>
                Luxembourg
              </option>
              <option data-kt-flag='flags/macao.svg' value='MO'>
                Macao
              </option>
              <option data-kt-flag='flags/madagascar.svg' value='MG'>
                Madagascar
              </option>
              <option data-kt-flag='flags/malawi.svg' value='MW'>
                Malawi
              </option>
              <option data-kt-flag='flags/malaysia.svg' value='MY'>
                Malaysia
              </option>
              <option data-kt-flag='flags/maldives.svg' value='MV'>
                Maldives
              </option>
              <option data-kt-flag='flags/mali.svg' value='ML'>
                Mali
              </option>
              <option data-kt-flag='flags/malta.svg' value='MT'>
                Malta
              </option>
              <option data-kt-flag='flags/marshall-island.svg' value='MH'>
                Marshall Islands
              </option>
              <option data-kt-flag='flags/martinique.svg' value='MQ'>
                Martinique
              </option>
              <option data-kt-flag='flags/mauritania.svg' value='MR'>
                Mauritania
              </option>
              <option data-kt-flag='flags/mauritius.svg' value='MU'>
                Mauritius
              </option>
              <option data-kt-flag='flags/mexico.svg' value='MX'>
                Mexico
              </option>
              <option data-kt-flag='flags/micronesia.svg' value='FM'>
                Micronesia, Federated States of
              </option>
              <option data-kt-flag='flags/moldova.svg' value='MD'>
                Moldova, Republic of
              </option>
              <option data-kt-flag='flags/monaco.svg' value='MC'>
                Monaco
              </option>
              <option data-kt-flag='flags/mongolia.svg' value='MN'>
                Mongolia
              </option>
              <option data-kt-flag='flags/montenegro.svg' value='ME'>
                Montenegro
              </option>
              <option data-kt-flag='flags/montserrat.svg' value='MS'>
                Montserrat
              </option>
              <option data-kt-flag='flags/morocco.svg' value='MA'>
                Morocco
              </option>
              <option data-kt-flag='flags/mozambique.svg' value='MZ'>
                Mozambique
              </option>
              <option data-kt-flag='flags/myanmar.svg' value='MM'>
                Myanmar
              </option>
              <option data-kt-flag='flags/namibia.svg' value='NA'>
                Namibia
              </option>
              <option data-kt-flag='flags/nauru.svg' value='NR'>
                Nauru
              </option>
              <option data-kt-flag='flags/nepal.svg' value='NP'>
                Nepal
              </option>
              <option data-kt-flag='flags/netherlands.svg' value='NL'>
                Netherlands
              </option>
              <option data-kt-flag='flags/new-zealand.svg' value='NZ'>
                New Zealand
              </option>
              <option data-kt-flag='flags/nicaragua.svg' value='NI'>
                Nicaragua
              </option>
              <option data-kt-flag='flags/niger.svg' value='NE'>
                Niger
              </option>
              <option data-kt-flag='flags/nigeria.svg' value='NG'>
                Nigeria
              </option>
              <option data-kt-flag='flags/niue.svg' value='NU'>
                Niue
              </option>
              <option data-kt-flag='flags/norfolk-island.svg' value='NF'>
                Norfolk Island
              </option>
              <option data-kt-flag='flags/northern-mariana-islands.svg' value='MP'>
                Northern Mariana Islands
              </option>
              <option data-kt-flag='flags/norway.svg' value='NO'>
                Norway
              </option>
              <option data-kt-flag='flags/oman.svg' value='OM'>
                Oman
              </option>
              <option data-kt-flag='flags/pakistan.svg' value='PK'>
                Pakistan
              </option>
              <option data-kt-flag='flags/palau.svg' value='PW'>
                Palau
              </option>
              <option data-kt-flag='flags/palestine.svg' value='PS'>
                Palestinian Territory, Occupied
              </option>
              <option data-kt-flag='flags/panama.svg' value='PA'>
                Panama
              </option>
              <option data-kt-flag='flags/papua-new-guinea.svg' value='PG'>
                Papua New Guinea
              </option>
              <option data-kt-flag='flags/paraguay.svg' value='PY'>
                Paraguay
              </option>
              <option data-kt-flag='flags/peru.svg' value='PE'>
                Peru
              </option>
              <option data-kt-flag='flags/philippines.svg' value='PH'>
                Philippines
              </option>
              <option data-kt-flag='flags/poland.svg' value='PL'>
                Poland
              </option>
              <option data-kt-flag='flags/portugal.svg' value='PT'>
                Portugal
              </option>
              <option data-kt-flag='flags/puerto-rico.svg' value='PR'>
                Puerto Rico
              </option>
              <option data-kt-flag='flags/qatar.svg' value='QA'>
                Qatar
              </option>
              <option data-kt-flag='flags/romania.svg' value='RO'>
                Romania
              </option>
              <option data-kt-flag='flags/russia.svg' value='RU'>
                Russian Federation
              </option>
              <option data-kt-flag='flags/rwanda.svg' value='RW'>
                Rwanda
              </option>
              <option data-kt-flag='flags/st-barts.svg' value='BL'>
                Saint Barthélemy
              </option>
              <option data-kt-flag='flags/saint-kitts-and-nevis.svg' value='KN'>
                Saint Kitts and Nevis
              </option>
              <option data-kt-flag='flags/st-lucia.svg' value='LC'>
                Saint Lucia
              </option>
              <option data-kt-flag='flags/sint-maarten.svg' value='MF'>
                Saint Martin (French part)
              </option>
              <option data-kt-flag='flags/st-vincent-and-the-grenadines.svg' value='VC'>
                Saint Vincent and the Grenadines
              </option>
              <option data-kt-flag='flags/samoa.svg' value='WS'>
                Samoa
              </option>
              <option data-kt-flag='flags/san-marino.svg' value='SM'>
                San Marino
              </option>
              <option data-kt-flag='flags/sao-tome-and-prince.svg' value='ST'>
                Sao Tome and Principe
              </option>
              <option data-kt-flag='flags/saudi-arabia.svg' value='SA'>
                Saudi Arabia
              </option>
              <option data-kt-flag='flags/senegal.svg' value='SN'>
                Senegal
              </option>
              <option data-kt-flag='flags/serbia.svg' value='RS'>
                Serbia
              </option>
              <option data-kt-flag='flags/seychelles.svg' value='SC'>
                Seychelles
              </option>
              <option data-kt-flag='flags/sierra-leone.svg' value='SL'>
                Sierra Leone
              </option>
              <option data-kt-flag='flags/singapore.svg' value='SG'>
                Singapore
              </option>
              <option data-kt-flag='flags/sint-maarten.svg' value='SX'>
                Sint Maarten (Dutch part)
              </option>
              <option data-kt-flag='flags/slovakia.svg' value='SK'>
                Slovakia
              </option>
              <option data-kt-flag='flags/slovenia.svg' value='SI'>
                Slovenia
              </option>
              <option data-kt-flag='flags/solomon-islands.svg' value='SB'>
                Solomon Islands
              </option>
              <option data-kt-flag='flags/somalia.svg' value='SO'>
                Somalia
              </option>
              <option data-kt-flag='flags/south-africa.svg' value='ZA'>
                South Africa
              </option>
              <option data-kt-flag='flags/south-korea.svg' value='KR'>
                South Korea
              </option>
              <option data-kt-flag='flags/south-sudan.svg' value='SS'>
                South Sudan
              </option>
              <option data-kt-flag='flags/spain.svg' value='ES'>
                Spain
              </option>
              <option data-kt-flag='flags/sri-lanka.svg' value='LK'>
                Sri Lanka
              </option>
              <option data-kt-flag='flags/sudan.svg' value='SD'>
                Sudan
              </option>
              <option data-kt-flag='flags/suriname.svg' value='SR'>
                Suriname
              </option>
              <option data-kt-flag='flags/swaziland.svg' value='SZ'>
                Swaziland
              </option>
              <option data-kt-flag='flags/sweden.svg' value='SE'>
                Sweden
              </option>
              <option data-kt-flag='flags/switzerland.svg' value='CH'>
                Switzerland
              </option>
              <option data-kt-flag='flags/syria.svg' value='SY'>
                Syrian Arab Republic
              </option>
              <option data-kt-flag='flags/taiwan.svg' value='TW'>
                Taiwan, Province of China
              </option>
              <option data-kt-flag='flags/tajikistan.svg' value='TJ'>
                Tajikistan
              </option>
              <option data-kt-flag='flags/tanzania.svg' value='TZ'>
                Tanzania, United Republic of
              </option>
              <option data-kt-flag='flags/thailand.svg' value='TH'>
                Thailand
              </option>
              <option data-kt-flag='flags/togo.svg' value='TG'>
                Togo
              </option>
              <option data-kt-flag='flags/tokelau.svg' value='TK'>
                Tokelau
              </option>
              <option data-kt-flag='flags/tonga.svg' value='TO'>
                Tonga
              </option>
              <option data-kt-flag='flags/trinidad-and-tobago.svg' value='TT'>
                Trinidad and Tobago
              </option>
              <option data-kt-flag='flags/tunisia.svg' value='TN'>
                Tunisia
              </option>
              <option data-kt-flag='flags/turkey.svg' value='TR'>
                Turkey
              </option>
              <option data-kt-flag='flags/turkmenistan.svg' value='TM'>
                Turkmenistan
              </option>
              <option data-kt-flag='flags/turks-and-caicos.svg' value='TC'>
                Turks and Caicos Islands
              </option>
              <option data-kt-flag='flags/tuvalu.svg' value='TV'>
                Tuvalu
              </option>
              <option data-kt-flag='flags/uganda.svg' value='UG'>
                Uganda
              </option>
              <option data-kt-flag='flags/ukraine.svg' value='UA'>
                Ukraine
              </option>
              <option data-kt-flag='flags/united-arab-emirates.svg' value='AE'>
                United Arab Emirates
              </option>
              <option data-kt-flag='flags/united-kingdom.svg' value='GB'>
                United Kingdom
              </option>
              <option data-kt-flag='flags/united-states.svg' value='US'>
                United States
              </option>
              <option data-kt-flag='flags/uruguay.svg' value='UY'>
                Uruguay
              </option>
              <option data-kt-flag='flags/uzbekistan.svg' value='UZ'>
                Uzbekistan
              </option>
              <option data-kt-flag='flags/vanuatu.svg' value='VU'>
                Vanuatu
              </option>
              <option data-kt-flag='flags/venezuela.svg' value='VE'>
                Venezuela, Bolivarian Republic of
              </option>
              <option data-kt-flag='flags/vietnam.svg' value='VN'>
                Vietnam
              </option>
              <option data-kt-flag='flags/virgin-islands.svg' value='VI'>
                Virgin Islands
              </option>
              <option data-kt-flag='flags/yemen.svg' value='YE'>
                Yemen
              </option>
              <option data-kt-flag='flags/zambia.svg' value='ZM'>
                Zambia
              </option>
              <option data-kt-flag='flags/zimbabwe.svg' value='ZW'>
                Zimbabwe
              </option>
            </select>
            {formik.touched.address && formik.errors.address && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.address}</span>
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Address</label>
            <input
              placeholder='Address'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('address')}
              className={clsx(
                'form-control form-control-solid',
                {'is-invalid': formik.touched.address && formik.errors.address},
                {
                  'is-valid': formik.touched.address && !formik.errors.address,
                }
              )}
            />
            {formik.touched.address && formik.errors.address && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.address}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Mobile Number</label>

            <PhoneInput
              value={formik.values.mobileNumber}
              onChange={(e) => formik.setFieldValue('mobileNumber', e)}
              containerClass={clsx('p-0')}
              inputClass={clsx('form-control-solid w-100')}
              country={country.toLowerCase()}
            />
            {formik.touched.mobileNumber && formik.errors.mobileNumber && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.mobileNumber}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-8' data-kt-password-meter='true'>
            <div className='mb-1'>
              <label className='form-label fw-bolder text-dark fs-6'>Password</label>
              <div className='position-relative mb-3'>
                <input
                  type={showPassword ? 'text' : 'password'}
                  placeholder='Password'
                  autoComplete='off'
                  {...formik.getFieldProps('password')}
                  className={clsx(
                    'form-control form-control-solid',
                    {
                      'is-invalid': formik.touched.password && formik.errors.password,
                    },
                    {
                      'is-valid': formik.touched.password && !formik.errors.password,
                    }
                  )}
                />
                <button
                  type='button'
                  className='btn btn-sm btn-icon position-absolute end-0 top-50 translate-middle-y'
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? (
                    <i className='bi bi-eye-slash'></i> // Icon for hiding password
                  ) : (
                    <i className='bi bi-eye'></i> // Icon for showing password
                  )}
                </button>
                {formik.touched.password && formik.errors.password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.password}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='fv-row mb-8'>
            {isReferralInputOpen ? (
              <label
                className='form-label fw-bolder text-dark fs-6'
                onClick={() => setReferralInputOpen(false)}
              >
                Referral Code (Optional)
              </label>
            ) : (
              <label
                className='text-primary fw-bold fs-6'
                onClick={() => setReferralInputOpen(true)}
              >
                Got A Referral Code?
              </label>
            )}

            {isReferralInputOpen ? (
              <div className='fv-row mb-8'>
                <div className='mb-1'>
                  <div className='position-relative mb-3'>
                    <input
                      type='text'
                      placeholder='Enter Code'
                      autoComplete='off'
                      defaultValue={`${id}`}
                      {...formik.getFieldProps('referralCode')}
                      className={clsx('form-control form-control-solid')}
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>

          <div className='d-flex flex-stack'>
            <button
              type='submit'
              id='kt_sign_up_submit'
              className='btn btn-primary mb-5'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Submit</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  )
}
