/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import {toast} from 'react-toastify'
import {login} from '../../../../api/_request.login'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        await toast
          .promise(login(values.email, values.password), {
            pending: 'Requesting...',
            success: 'Logged in successfully',
            error: 'Something went wrong 😢',
          })
          .then((response) => {
            saveAuth(response.data)
            setCurrentUser(response.data.user)
            navigate('/')
          })
          .catch((error) => {
            saveAuth(undefined)
            setStatus('The login details are incorrect')
            toast.info(error.response.data.message)
          })
          .finally(() => {
            setLoading(false)
            setSubmitting(false)
          })
      } catch (error) {
        saveAuth(undefined)
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <>
      <div className='d-flex flex-stack py-2'>
        <div className='me-2'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/pbv.png')} className='h-100px mb-6' />
        </div>
        <div className=''>
          <div className='text-gray-500 text-center fw-semibold fs-6'>
            Not a member yet?{' '}
            <Link to='/auth/registration' className='link-primary'>
              Sign up
            </Link>
          </div>
        </div>
      </div>
      <div className='py-20'>
        <form
          className='form w-100'
          onSubmit={formik.handleSubmit}
          noValidate
          id='kt_login_signin_form'
        >
          {/* begin::Heading */}
          <div className='text-start mb-10'>
            <h1 className='text-dark fw-bolder mb-3 fs-3x'>Sign In</h1>
            <div className='text-gray-500 fw-semibold fs-6'>Empowering Business Key Feature</div>
          </div>
          <div className='fv-row mb-7'>
            <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
            <input
              placeholder='Email'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control form-control-solid',
                {'is-invalid': formik.touched.email && formik.errors.email},
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
              type='email'
              name='email'
              autoComplete='off'
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            )}
          </div>
          <div className='fv-row mb-3'>
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
            <input
              type='password'
              placeholder='Password'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>

          <div className='d-flex justify-content-between align-items-center flex-wrap gap-3 fs-base fw-semibold mb-8'>
            <label htmlFor='rememberMe' className='d-flex align-items-center'>
              <input type='checkbox' id='rememberMe' name='rememberMe' className='me-2' />
              Remember Me
            </label>

            <Link to='/auth/forgot-password' className='link-primary'>
              Forgot Password?
            </Link>
          </div>
          <div className='d-flex flex-stack'>
            <button
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-primary me-2 flex-shrink'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Sign in</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Action */}
        </form>
      </div>
      <div className='position-relative' />
      {/* <LanguageSelector /> */}
    </>
  )
}
