import {Link} from 'react-router-dom'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {MutableRefObject, useEffect, useRef, useState} from 'react'
import {ToggleComponent} from '../../../assets/ts/components'
import {Menu} from '@headlessui/react'
import clsx from 'clsx'
import {useAuth} from '../../../../app/modules/auth'
import {useDispatch} from 'react-redux'
import axios from 'axios'
import {setPermissions} from '../../../../redux/slicers/permissionSlice'

type PropsType = {
  sidebarRef: MutableRefObject<HTMLDivElement | null>
}

const SidebarProfile = (props: PropsType) => {
  const toggleRef = useRef<HTMLDivElement>(null)

  const {currentUser} = useAuth()

  useEffect(() => {
    setTimeout(() => {
      const toggleObj = ToggleComponent.getInstance(toggleRef.current!) as ToggleComponent | null

      if (toggleObj === null) {
        return
      }

      // Add a class to prevent sidebar hover effect after toggle click
      toggleObj.on('kt.toggle.change', function () {
        // Set animation state
        props.sidebarRef.current!.classList.add('animating')

        // Wait till animation finishes
        setTimeout(function () {
          // Remove animation state
          props.sidebarRef.current!.classList.remove('animating')
        }, 300)
      })
    }, 600)
  }, [toggleRef, props.sidebarRef])

  const [permission, setPermission] = useState<any>([])
  const baseUrl = process.env.REACT_APP_BASE_URL
  const dispatch = useDispatch()
  useEffect(() => {
    const getuserPermission = async () => {
      await axios
        .post(
          `${baseUrl}/clientrole/getrolebymerchant/${currentUser?.id}`,
          {
            role: currentUser?.role,
          },
          {
            headers: {
              Authorization: `Bearer ${currentUser?.api_token}`,
            },
          }
        )
        .then((res) => {
          setPermission(res.data.roles[0].permissions)
          dispatch(setPermissions(res.data.roles[0].permissions))
          console.log(permission)
        })
    }
    getuserPermission()
  }, [])

  return (
    <div className='app-sidebar-profile d-flex flex-stack px-6 py-2' id='kt_app_sidebar_logo'>
      <Link to='/profile'>
        <img
          src={currentUser?.profilePicture}
          alt=''
          className='h-35px app-sidebar-profile-default rounded-2'
        />
        <img
          alt='Logo'
          src={currentUser?.profilePicture}
          className='h-35px app-sidebar-profile-minimize rounded-2'
        />
      </Link>
      <div className='d-flex flex-column ms-2 justify-content-between app-sidebar-profile-details'>
        <span className='text-nowrap ' style={{color: 'white'}}>
          {currentUser?.fullname}
        </span>
        <div className='d-flex align-items-center'>
          <span className='badge badge-success badge-circle w-5px h-5px me-1' />
          <span className='text-success fs-10'>online</span>
        </div>
      </div>
      <div className='ps-lg-12 app-sidebar-profile-settings position-relative'>
        <Menu>
          {({open, close}) => (
            <>
              <Menu.Button className='btn btn-sm'>
                <KTIcon iconName='setting-2' className='fs-3' />
              </Menu.Button>
              <Menu.Items
                className={clsx(
                  'menu menu-sub menu-sub-lg-dropdown menu-column menu-rounded fw-bold fs-7 w-200px py-4 overflow-auto',
                  open && 'show'
                )}
                style={{
                  position: 'absolute',
                  zIndex: 9999,
                  left: 0,
                }}
              >
                <Menu.Item as='div' className='menu-item'>
                  <Link to='/profile' onClick={close} className='menu-link'>
                    <span className='menu-bullet'>
                      <span className='bullet bullet-dot'></span>
                    </span>
                    Profile Settings
                  </Link>
                </Menu.Item>

                {(permission?.paymentmanagement?.read ||
                  permission?.paymentmanagement?.create ||
                  permission?.paymentmanagement?.delete ||
                  permission?.paymentmanagement?.update ||
                  currentUser?.role === 'client-store-owner') && (
                  <Menu.Item as='div' className='menu-item'>
                    <Link to='/settings/payment-settings' onClick={close} className='menu-link'>
                      <span className='menu-bullet'>
                        <span className='bullet bullet-dot'></span>
                      </span>
                      Payment settings
                    </Link>
                  </Menu.Item>
                )}
              </Menu.Items>
            </>
          )}
        </Menu>
      </div>
    </div>
  )
}

export {SidebarProfile}
