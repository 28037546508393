import {FC} from 'react'
import {numberWithCommas} from '../../../helpers'
import {useAuth} from '../../../app/modules/auth'

type CustomerFeedProps = {
  title: string
  value: string | number
}

const Feed: FC<CustomerFeedProps> = ({title, value}) => {
  const {currentUser} = useAuth()
  const currencySymbol = currentUser?.currencySymbol || '$'
  return (
    <div className='card card-flush card-xl-stretch h-md-100'>
      <div className='card-header p-5'>
        <div className='card-title d-flex flex-column'>
          <div className='d-flex align-items-center'>
            <span className='fs-4 fw-semibold text-gray-400 me-1 align-self-start'>
              {currencySymbol}
            </span>
            <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>
              {numberWithCommas(value as number)}
            </span>
          </div>
          <span className='text-gray-400 pt-1 fw-semibold fs-6 text-uppercase'>{title}</span>
        </div>
      </div>
    </div>
  )
}

export default Feed
