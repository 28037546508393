import {Column, HeaderProps} from 'react-table'
import {FC, PropsWithChildren, useEffect, useState} from 'react'
import {Menu} from '@headlessui/react'

import {User} from '../../../../app/modules/apps/user-management/users-list/core/_models'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {KTIcon} from '../../../../_metronic/helpers/components/KTIcon'
import {ID, toAbsoluteUrl} from '../../../../_metronic/helpers'
import EditUserModal from './EditUserModal'
import usePopup from '../../../../hooks/usePopup'
import {renderBadge} from '../../../../helpers'
import {Customer} from '../../../../_models/customer'
import {deleteCustomer} from '../../../../api/delete/deleteCustomer'
import {useDispatch} from 'react-redux'
import {useAuth} from '../../../../app/modules/auth'
import {toast} from 'react-toastify'
import {addSale} from '../../../../redux/slicers/saleSlice'

type TableHeaderProps = {
  className?: string
  title?: string
  tableProps: PropsWithChildren<HeaderProps<Customer>>
}

type InfoCellProps = {
  user: Customer
}

const TableHeader: FC<TableHeaderProps> = ({className, title, tableProps}) => {
  return (
    <th
      {...tableProps.column.getHeaderProps()}
      className={clsx(className)}
      style={{cursor: 'pointer'}}
    >
      {title}
    </th>
  )
}

const UserInfoCell: FC<InfoCellProps> = ({user: client}) => {
  const slug = client.fullname?.toLowerCase().replace(' ', '-')
  const initial = client.fullname?.charAt(0)
  return (
    <div className='d-flex align-items-center'>
      <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
        {/* <Link to={'/user-management/users/' + slug} className='text-dark text-hover-primary'> */}
        {client.profilePictrure ? (
          <div className='symbol-label'>
            <img src={client.profilePictrure} alt={client.fullname} className='w-100' />
          </div>
        ) : (
          <div className={clsx('symbol-label fs-3', `bg-light-info`, `text-info`)}>{initial}</div>
        )}
        {/* </Link> */}
      </div>
      <div className='d-flex flex-column'>
        {/* <Link
          to={'/user-management/users/' + slug}
          className='text-gray-800 text-hover-primary mb-1'
        > */}
        <span className='text-gray-800 text-hover-primary mb-1'>{client.fullname}</span>
        {/* </Link> */}
      </div>
    </div>
  )
}
const StatusCell: FC<{item: any}> = ({item}) => (
  <div className={clsx(renderBadge(item.status))}>{item.status}</div>
)

// const UserActionsCell: FC<{user: Customer}> = ({user}) => {
//   const [trigger] = usePopup()

//   const [modalOpen, setModalOpen] = useState(false)
//   const [isUserLoading, setUserLoading] = useState(true)
//   const [_user, setUser] = useState({
//     avatar: 'avatars/300-6.jpg',
//     position: 'Art Director',
//     role: 'Administrator',
//     name: '',
//     email: '',
//   })

//   const closeModal = () => {
//     setModalOpen(false)
//   }

//   const getUser = () => {
//     setUserLoading(true)
//     setTimeout(() => {
//       setUserLoading(false)
//     }, 1000)
//   }

//   useEffect(() => {
//     getUser()
//   }, [])

//   const openEditModal = () => {
//     console.log('open edit modal', user)
//     setModalOpen(true)
//   }

//   const deleteItem = () => {
//     trigger(
//       'Delete',
//       () => console.log('confirm'),
//       () => console.log('reject'),
//       'Are you sure you want to delete this client?'
//     )
//   }

//   return (
//     <>
//       <Menu>
//         {({open}) => (
//           <>
//             <Menu.Button
//               className='btn btn-light btn-active-light-primary btn-sm'
//               data-kt-menu-trigger='click'
//               data-kt-menu-placement='bottom-end'
//             >
//               Actions
//               <KTIcon iconName='down' className='fs-5 m-0' />
//             </Menu.Button>
//             <Menu.Items
//               className={clsx(
//                 'menu menu-sub d-flex menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4',
//                 open && 'show'
//               )}
//               style={{
//                 position: 'absolute',
//                 zIndex: 105,
//               }}
//             >
//               <Menu.Item as='div' className='menu-item px-3'>
//                 <span
//                   className='menu-link px-3'
//                   // onClick={openEditModal}
//                 >
//                   Edit
//                 </span>
//               </Menu.Item>
//               <Menu.Item as='div' className='menu-item px-3'>
//                 <span
//                   className='menu-link px-3'
//                   data-kt-users-table-filter='delete_row'
//                   onClick={() => deleteItem()}
//                 >
//                   Delete
//                 </span>
//               </Menu.Item>
//             </Menu.Items>
//           </>
//         )}
//       </Menu>
//       {modalOpen && (
//         <EditUserModal user={user} close={closeModal} isUserLoading={isUserLoading} isEdit />
//       )}
//     </>
//   )
// }
const ActionsCell: FC<{item: any}> = ({item}) => {
  const [trigger] = usePopup()
  const {currentUser} = useAuth()
  const dispatch = useDispatch()
  const deleteItem = () => {
    trigger(
      'Delete',
      () => {
        deleteCustomer(currentUser?.api_token as string, item._id)
          .then((result: any) => {
            console.log(result)
            toast.success('Customer Deleted Successfully')
            dispatch(addSale('customer deleted successfully'))
          })
          .catch(() => {
            toast.error('Something went wrong please try again later')
          })
          .finally(() => {})
      },
      () => console.log('reject')
    )
  }

  return (
    <>
      <Menu>
        {({open}) => (
          <>
            <Menu.Button
              className='btn btn-light btn-active-light-primary btn-sm text-nowrap'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
            >
              Actions
              <KTIcon iconName='down' className='fs-5 m-0' />
            </Menu.Button>
            <Menu.Items
              className={clsx(
                'menu menu-sub d-flex menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4',
                open && 'show'
              )}
              style={{
                position: 'absolute',
                zIndex: 105,
              }}
            >
              <Menu.Item as='div' className='menu-item px-3'>
                <Link to={`/contacts/customer/${item._id}`} className='menu-link px-3'>
                  View
                </Link>
              </Menu.Item>
              <Menu.Item as='div' className='menu-item px-3'>
                <Link to={`/contacts/edit-customer/${item?._id}`} className='menu-link px-3'>
                  Edit
                </Link>
              </Menu.Item>
              <Menu.Item as='div' className='menu-item px-3'>
                <Link to='#' className='menu-link px-3' onClick={deleteItem}>
                  Delete
                </Link>
              </Menu.Item>
            </Menu.Items>
          </>
        )}
      </Menu>
    </>
  )
}

const columns: ReadonlyArray<Column<Customer>> = [
  {
    id: 'select',
    Header: (props) => (
      <th className='w-25px'>
        <div className='form-check form-check-sm form-check-custom form-check-solid'>
          <input
            className='form-check-input'
            type='checkbox'
            value='1'
            data-kt-check='true'
            data-kt-check-target='.widget-9-check'
          />
        </div>
      </th>
    ),
    Cell: (props) => (
      <div className='form-check form-check-sm form-check-custom form-check-solid'>
        <input className='form-check-input widget-9-check' type='checkbox' value='1' />
      </div>
    ),
  },
  {
    id: 'client_id',
    Header: (props) => <TableHeader tableProps={props} title='Client ID' className='min-w-125px' />,
    accessor: '_id',
  },
  {
    id: 'client',
    Header: (props) => <TableHeader tableProps={props} title='Client' className='min-w-125px' />,
    accessor: 'fullname',
    Cell: ({...props}) => {
      return <UserInfoCell user={props.data[props.row.index]} />
    },
  },
  {
    id: 'status',
    Header: (props) => <TableHeader tableProps={props} title='Status' className='min-w-125px' />,
    accessor: (row) => row.status,
    Cell: ({...props}) => {
      return <StatusCell item={props.data[props.row.index]} />
    },
  },
  {
    id: 'date',
    Header: (props) => <TableHeader tableProps={props} title='Date' className='min-w-125px' />,
    accessor: 'createdAt',
  },

  {
    Header: (props) => (
      <TableHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ActionsCell item={props.data[props.row.index]} />,
  },
]

export {columns}
