import {createSlice} from '@reduxjs/toolkit'

export interface LoadingSlice {
  loading: boolean
}

const initialState: LoadingSlice = {
  loading: false,
}

const loadingStateSlice = createSlice({
  name: 'loadingState',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload
    },
  },
})

export const {setLoading} = loadingStateSlice.actions

export default loadingStateSlice.reducer

export const selectLoading = (state) => state.loading.loading
