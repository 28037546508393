import {configureStore} from '@reduxjs/toolkit'
import saleReducer from './slicers/saleSlice'
import profileReducer from './slicers/profileSlice'
import permissionReducer from './slicers/permissionSlice'
import loadingReducer from './slicers/loadingSlice'

export const store = configureStore({
  reducer: {
    sale: saleReducer,
    profile: profileReducer,
    permission: permissionReducer,
    loading: loadingReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
