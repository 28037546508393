import {createSlice} from '@reduxjs/toolkit'

export interface SaleState {
  message: string
}

const initialState: SaleState = {
  message: '',
}

const saleStateSlice = createSlice({
  name: 'saleState',
  initialState,
  reducers: {
    addSale: (state, action) => {
      state.message = action.payload.message + ' ' + new Date().getTime()
    },
    updateSale: (state, action) => {
      state.message = action.payload.message + ' ' + new Date().getTime()
    },
    deleteSale: (state, action) => {
      state.message = action.payload.message + ' ' + new Date().getTime()
    },
  },
})

export const {addSale, updateSale, deleteSale} = saleStateSlice.actions

export default saleStateSlice.reducer
