/* eslint-disable react/jsx-no-target-blank */
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useAuth} from '../../../../../app/modules/auth'
import {Link} from 'react-router-dom'
import axios from 'axios'
import {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {setPermissions} from '../../../../../redux/slicers/permissionSlice'

const SidebarMenuCustom = () => {
  const {currentUser} = useAuth()
  const baseUrl = process.env.REACT_APP_BASE_URL
  const dispatch = useDispatch()
  const [permission, setPermission] = useState<any>([])

  useEffect(() => {
    const getuserPermission = async () => {
      await axios
        .post(
          `${baseUrl}/clientrole/getrolebymerchant/${currentUser?.id}`,
          {
            role: currentUser?.role,
          },
          {
            headers: {
              Authorization: `Bearer ${currentUser?.api_token}`,
            },
          }
        )
        .then((res) => {
          setPermission(res.data.roles[0].permissions)
          dispatch(setPermissions(res.data.roles[0].permissions))
          console.log(permission)
        })
    }
    getuserPermission()
  }, [])

  return (
    <>
      <SidebarMenuItem to='/dashboard' icon='element-11' title='Home' fontIcon='bi-layers' />
      {/* {(permission?.dashboard?.read ||
        permission?.dashboard?.create ||
        permission?.dashboard?.delete ||
        permission?.dashboard?.update ||
        currentUser?.role === 'client-store-owner') && (
        
      )} */}

      {(permission?.salesmanagement?.read === true ||
        permission?.salesmanagement?.create === true ||
        permission?.salesmanagement?.delete === true ||
        permission?.salesmanagement?.update === true ||
        permission?.invoicesmanagement?.read ||
        permission?.invoicesmanagement?.create ||
        permission?.invoicesmanagement?.delete ||
        permission?.invoicesmanagement?.update ||
        permission?.salereturnsmanagement?.read ||
        permission?.salereturnsmanagement?.create ||
        permission?.salereturnsmanagement?.delete ||
        permission?.salereturnsmanagement?.update ||
        permission?.quotationsmanagement?.read ||
        permission?.quotationsmanagement?.create ||
        permission?.quotationsmanagement?.delete ||
        permission?.quotationsmanagement?.update ||
        currentUser?.role === 'client-store-owner') && (
        <SidebarMenuItemWithSub to='/sales' icon='basket' title='Sales'>
          {(permission?.salesmanagement?.read === true ||
            permission?.salesmanagement?.create === true ||
            permission?.salesmanagement?.delete === true ||
            permission?.salesmanagement?.update === true ||
            currentUser?.role === 'client-store-owner') && (
            <SidebarMenuItem to='/sales/sales-list' hasBullet title='Sales' />
          )}

          {/* /sales/pos-sales */}
          {(permission?.salesmanagement?.read === true ||
            permission?.salesmanagement?.create === true ||
            permission?.salesmanagement?.delete === true ||
            permission?.salesmanagement?.update === true ||
            currentUser?.role === 'client-store-owner') && (
            <SidebarMenuItem to='/sales/pos-orders' hasBullet title='POS Orders' />
          )}

          {(permission?.invoicesmanagement?.read ||
            permission?.invoicesmanagement?.create ||
            permission?.invoicesmanagement?.delete ||
            permission?.invoicesmanagement?.update ||
            currentUser?.role === 'client-store-owner') && (
            <SidebarMenuItem to='/sales/invoices-list' hasBullet title='Invoices' />
          )}

          {(permission?.salereturnsmanagement?.read ||
            permission?.salereturnsmanagement?.create ||
            permission?.salereturnsmanagement?.delete ||
            permission?.salereturnsmanagement?.update ||
            currentUser?.role === 'client-store-owner') && (
            <SidebarMenuItem to='/sales/returns' hasBullet title='Sales Returns' />
          )}

          {(permission?.quotationsmanagement?.read ||
            permission?.quotationsmanagement?.create ||
            permission?.quotationsmanagement?.delete ||
            permission?.quotationsmanagement?.update ||
            currentUser?.role === 'client-store-owner') && (
            <SidebarMenuItem to='/sales/quotations' hasBullet title='Quotations' />
          )}
        </SidebarMenuItemWithSub>
      )}

      {/* Inventory menu */}
      {(permission?.inventorymanagement?.read ||
        permission?.inventorymanagement?.create ||
        permission?.inventorymanagement?.delete ||
        permission?.inventorymanagement?.update ||
        permission?.inventoryvaluationmanagement?.read ||
        permission?.inventoryvaluationmanagement?.create ||
        permission?.inventoryvaluationmanagement?.delete ||
        permission?.inventoryvaluationmanagement?.update ||
        currentUser?.role === 'client-store-owner') && (
        <SidebarMenuItemWithSub to='/inventory' icon='shop' title='Inventory'>
          {(permission?.inventorymanagement?.read ||
            permission?.inventorymanagement?.create ||
            permission?.inventorymanagement?.delete ||
            permission?.inventorymanagement?.update ||
            currentUser?.role === 'client-store-owner') && (
            <SidebarMenuItem to='/inventory/inventory-list' hasBullet title='Inventory' />
          )}
          {(permission?.inventoryvaluationmanagement?.read ||
            permission?.inventoryvaluationmanagement?.create ||
            permission?.inventoryvaluationmanagement?.delete ||
            permission?.inventoryvaluationmanagement?.update ||
            currentUser?.role === 'client-store-owner') && (
            <SidebarMenuItem to='/inventory/valuation' hasBullet title='Inventory Valuation' />
          )}
        </SidebarMenuItemWithSub>
      )}

      {/* Expenses menu */}
      {(permission?.purcheaseordermanagement?.read ||
        permission?.purcheaseordermanagement?.create ||
        permission?.purcheaseordermanagement?.delete ||
        permission?.purcheaseordermanagement?.update ||
        permission?.billmanagement?.read ||
        permission?.billmanagement?.create ||
        permission?.billmanagement?.delete ||
        permission?.billmanagement?.update ||
        permission?.expensesmanagement?.read ||
        permission?.expensesmanagement?.create ||
        permission?.expensesmanagement?.delete ||
        permission?.expensesmanagement?.update ||
        currentUser?.role === 'client-store-owner') && (
        <SidebarMenuItemWithSub to='/expenses' icon='bill' title='Expenses'>
          {(permission?.purcheaseordermanagement?.read ||
            permission?.purcheaseordermanagement?.create ||
            permission?.purcheaseordermanagement?.delete ||
            permission?.purcheaseordermanagement?.update ||
            currentUser?.role === 'client-store-owner') && (
            <SidebarMenuItem to='/expenses/purchase-order-list' hasBullet title='Purchase Orders' />
          )}

          {(permission?.billmanagement?.read ||
            permission?.billmanagement?.create ||
            permission?.billmanagement?.delete ||
            permission?.billmanagement?.update ||
            currentUser?.role === 'client-store-owner') && (
            <SidebarMenuItem to='/expenses/bills' hasBullet title='Bills' />
          )}

          {(permission?.expensesmanagement?.read ||
            permission?.expensesmanagement?.create ||
            permission?.expensesmanagement?.delete ||
            permission?.expensesmanagement?.update ||
            currentUser?.role === 'client-store-owner') && (
            <SidebarMenuItem to='/expenses/expenses-list' hasBullet title='Expenses List' />
          )}
        </SidebarMenuItemWithSub>
      )}

      {/* Contacts menu */}
      {(permission?.customermanagement?.read ||
        permission?.customermanagement?.create ||
        permission?.customermanagement?.delete ||
        permission?.customermanagement?.update ||
        permission?.vendormanagement?.read ||
        permission?.vendormanagement?.create ||
        permission?.vendormanagement?.delete ||
        permission?.vendormanagement?.update ||
        permission?.suppliermanagement?.read ||
        permission?.suppliermanagement?.create ||
        permission?.suppliermanagement?.delete ||
        permission?.suppliermanagement?.update ||
        currentUser?.role === 'client-store-owner') && (
        <SidebarMenuItemWithSub to='/contacts' icon='address-book' title='Contacts'>
          {(permission?.customermanagement?.read ||
            permission?.customermanagement?.create ||
            permission?.customermanagement?.delete ||
            permission?.customermanagement?.update ||
            currentUser?.role === 'client-store-owner') && (
            <SidebarMenuItem to='/contacts/customers-list' hasBullet title='Customers' />
          )}

          {(permission?.vendormanagement?.read ||
            permission?.vendormanagement?.create ||
            permission?.vendormanagement?.delete ||
            permission?.vendormanagement?.update ||
            currentUser?.role === 'client-store-owner') && (
            <SidebarMenuItem to='/contacts/vendors-list' hasBullet title='Vendors' />
          )}

          {(permission?.suppliermanagement?.read ||
            permission?.suppliermanagement?.create ||
            permission?.suppliermanagement?.delete ||
            permission?.suppliermanagement?.update ||
            currentUser?.role === 'client-store-owner') && (
            <SidebarMenuItem to='/contacts/supplier-list' hasBullet title='Supplier' />
          )}
        </SidebarMenuItemWithSub>
      )}

      {/* Sales Reports Menu */}
      {(permission?.salereportmanagement?.read ||
        permission?.salereportmanagement?.create ||
        permission?.salereportmanagement?.delete ||
        permission?.salereportmanagement?.update ||
        permission?.purchasereportmanagement?.read ||
        permission?.purchasereportmanagement?.create ||
        permission?.purchasereportmanagement?.delete ||
        permission?.purchasereportmanagement?.update ||
        permission?.inventoryreportmanagement?.read ||
        permission?.inventoryreportmanagement?.create ||
        permission?.inventoryreportmanagement?.delete ||
        permission?.inventoryreportmanagement?.update ||
        permission?.invoicereportmanagement?.read ||
        permission?.invoicereportmanagement?.create ||
        permission?.invoicereportmanagement?.delete ||
        permission?.invoicereportmanagement?.update ||
        permission?.supplierreportmanagement?.read ||
        permission?.supplierreportmanagement?.create ||
        permission?.supplierreportmanagement?.delete ||
        permission?.supplierreportmanagement?.update ||
        permission?.customerreportmanagement?.read ||
        permission?.customerreportmanagement?.create ||
        permission?.customerreportmanagement?.delete ||
        permission?.customerreportmanagement?.update ||
        currentUser?.role === 'client-store-owner') && (
        <SidebarMenuItemWithSub to='/reports' icon='some-files' title='Reports'>
          {(permission?.salereportmanagement?.read ||
            permission?.salereportmanagement?.create ||
            permission?.salereportmanagement?.delete ||
            permission?.salereportmanagement?.update ||
            currentUser?.role === 'client-store-owner') && (
            <SidebarMenuItem to='/reports/sales-reports' hasBullet title='Sales Reports' />
          )}

          {(permission?.purchasereportmanagement?.read ||
            permission?.purchasereportmanagement?.create ||
            permission?.purchasereportmanagement?.delete ||
            permission?.purchasereportmanagement?.update ||
            currentUser?.role === 'client-store-owner') && (
            <SidebarMenuItem to='/reports/purchase-reports' hasBullet title='Purchase Reports' />
          )}
          {(permission?.inventoryreportmanagement?.read ||
            permission?.inventoryreportmanagement?.create ||
            permission?.inventoryreportmanagement?.delete ||
            permission?.inventoryreportmanagement?.update ||
            currentUser?.role === 'client-store-owner') && (
            <SidebarMenuItem to='/reports/inventory-reports' hasBullet title='Inventory Reports' />
          )}
          {(permission?.invoicereportmanagement?.read ||
            permission?.invoicereportmanagement?.create ||
            permission?.invoicereportmanagement?.delete ||
            permission?.invoicereportmanagement?.update ||
            currentUser?.role === 'client-store-owner') && (
            <SidebarMenuItem to='/reports/invoice-reports' hasBullet title='Invoice Reports' />
          )}
          {(permission?.supplierreportmanagement?.read ||
            permission?.supplierreportmanagement?.create ||
            permission?.supplierreportmanagement?.delete ||
            permission?.supplierreportmanagement?.update ||
            currentUser?.role === 'client-store-owner') && (
            <SidebarMenuItem to='/reports/supplier-reports' hasBullet title='Supplier Reports' />
          )}
          {(permission?.customerreportmanagement?.read ||
            permission?.customerreportmanagement?.create ||
            permission?.customerreportmanagement?.delete ||
            permission?.customerreportmanagement?.update ||
            currentUser?.role === 'client-store-owner') && (
            <SidebarMenuItem to='/reports/customer-reports' hasBullet title='Customer Reports' />
          )}
        </SidebarMenuItemWithSub>
      )}

      {/* POS */}
      {(permission?.posmanagement?.read ||
        permission?.posmanagement?.create ||
        permission?.posmanagement?.delete ||
        permission?.posmanagement?.update ||
        currentUser?.role === 'client-store-owner') && (
        <SidebarMenuItem to='/pos' icon='lots-shopping' title='POS' />
      )}

      {/* Purchases Menu */}
      {(permission?.purcheaselistmanagement?.read ||
        permission?.purcheaselistmanagement?.create ||
        permission?.purcheaselistmanagement?.delete ||
        permission?.purcheaselistmanagement?.update ||
        permission?.createpurcheasemanagement?.read ||
        permission?.createpurcheasemanagement?.create ||
        permission?.createpurcheasemanagement?.delete ||
        permission?.createpurcheasemanagement?.update ||
        permission?.importpurcheasemanagement?.read ||
        permission?.importpurcheasemanagement?.create ||
        permission?.importpurcheasemanagement?.delete ||
        permission?.invoiimportpurcheasemanagementesmanagement?.update ||
        permission?.purcheaseordermanagement?.read ||
        permission?.purcheaseordermanagement?.create ||
        permission?.purcheaseordermanagement?.delete ||
        permission?.purcheaseordermanagement?.update ||
        permission?.purcheasereturnmanagement?.read ||
        permission?.purcheasereturnmanagement?.create ||
        permission?.purcheasereturnmanagement?.delete ||
        permission?.purcheasereturnmanagement?.update ||
        currentUser?.role === 'client-store-owner') && (
        <SidebarMenuItemWithSub to='/purchases' icon='purchase' title='Purchases'>
          {(permission?.purcheaselistmanagement?.read ||
            permission?.purcheaselistmanagement?.create ||
            permission?.purcheaselistmanagement?.delete ||
            permission?.purcheaselistmanagement?.update ||
            currentUser?.role === 'client-store-owner') && (
            <SidebarMenuItem to='/purchases/purchases-list' hasBullet title='Purchases List' />
          )}
          {(permission?.createpurcheasemanagement?.read ||
            permission?.createpurcheasemanagement?.create ||
            permission?.createpurcheasemanagement?.delete ||
            permission?.createpurcheasemanagement?.update ||
            currentUser?.role === 'client-store-owner') && (
            <SidebarMenuItem to='/purchases/create-purchase' hasBullet title='Create Purchase' />
          )}
          {(permission?.importpurcheasemanagement?.read ||
            permission?.importpurcheasemanagement?.create ||
            permission?.importpurcheasemanagement?.delete ||
            permission?.invoiimportpurcheasemanagementesmanagement?.update ||
            currentUser?.role === 'client-store-owner') && (
            <SidebarMenuItem to='/purchases/import-purchase' hasBullet title='Import Purchase' />
          )}
          {(permission?.purcheaseordermanagement?.read ||
            permission?.purcheaseordermanagement?.create ||
            permission?.purcheaseordermanagement?.delete ||
            permission?.purcheaseordermanagement?.update ||
            currentUser?.role === 'client-store-owner') && (
            <SidebarMenuItem to='/purchases/purchase-orders' hasBullet title='Purchase Orders' />
          )}
          {(permission?.purcheasereturnmanagement?.read ||
            permission?.purcheasereturnmanagement?.create ||
            permission?.purcheasereturnmanagement?.delete ||
            permission?.purcheasereturnmanagement?.update ||
            currentUser?.role === 'client-store-owner') && (
            <SidebarMenuItem to='/purchases/purchase-returns' hasBullet title='Purchase Returns' />
          )}
        </SidebarMenuItemWithSub>
      )}

      {/* Products Page */}
      {(permission?.productlistmanagement?.read ||
        permission?.productlistmanagement?.create ||
        permission?.productlistmanagement?.delete ||
        permission?.productlistmanagement?.update ||
        permission?.categorymanagement?.read ||
        permission?.categorymanagement?.create ||
        permission?.categorymanagement?.delete ||
        permission?.categorymanagement?.update ||
        permission?.brandmanagement?.read ||
        permission?.brandmanagement?.create ||
        permission?.brandmanagement?.delete ||
        permission?.brandmanagement?.update ||
        permission?.subcategorymanagement?.read ||
        permission?.subcategorymanagement?.create ||
        permission?.subcategorymanagement?.delete ||
        permission?.subcategorymanagement?.update ||
        permission?.printbarcode?.read ||
        permission?.printbarcode?.create ||
        permission?.printbarcode?.delete ||
        permission?.printbarcode?.update ||
        permission?.importproductmanagement?.read ||
        permission?.importproductmanagement?.create ||
        permission?.importproductmanagement?.delete ||
        permission?.importproductmanagement?.update ||
        currentUser?.role === 'client-store-owner') && (
        <SidebarMenuItemWithSub to='/products' icon='cube-2' title='Products'>
          {(permission?.productlistmanagement?.read ||
            permission?.productlistmanagement?.create ||
            permission?.productlistmanagement?.delete ||
            permission?.productlistmanagement?.update ||
            currentUser?.role === 'client-store-owner') && (
            <SidebarMenuItem to='/products/products-list' hasBullet title='Products List' />
          )}
          {(permission?.categorymanagement?.read ||
            permission?.categorymanagement?.create ||
            permission?.categorymanagement?.delete ||
            permission?.categorymanagement?.update ||
            currentUser?.role === 'client-store-owner') && (
            <SidebarMenuItem to='/products/category-list' hasBullet title='Category List' />
          )}
          {(permission?.brandmanagement?.read ||
            permission?.brandmanagement?.create ||
            permission?.brandmanagement?.delete ||
            permission?.brandmanagement?.update ||
            currentUser?.role === 'client-store-owner') && (
            <SidebarMenuItem to='/products/brands-list' hasBullet title='Brands List' />
          )}
          {(permission?.subcategorymanagement?.read ||
            permission?.subcategorymanagement?.create ||
            permission?.subcategorymanagement?.delete ||
            permission?.subcategorymanagement?.update ||
            currentUser?.role === 'client-store-owner') && (
            <SidebarMenuItem to='/products/sub-category-list' hasBullet title='Sub Category List' />
          )}
          {(permission?.printbarcode?.read ||
            permission?.printbarcode?.create ||
            permission?.printbarcode?.delete ||
            permission?.printbarcode?.update ||
            currentUser?.role === 'client-store-owner') && (
            <SidebarMenuItem to='/products/print-barcode' hasBullet title='Print Barcode' />
          )}
          {(permission?.importproductmanagement?.read ||
            permission?.importproductmanagement?.create ||
            permission?.importproductmanagement?.delete ||
            permission?.importproductmanagement?.update ||
            currentUser?.role === 'client-store-owner') && (
            <SidebarMenuItem to='/products/import-product' hasBullet title='Import Product' />
          )}
        </SidebarMenuItemWithSub>
      )}

      {/* Role Management */}
      {(permission?.rolemanagement?.read ||
        permission?.rolemanagement?.create ||
        permission?.rolemanagement?.delete ||
        permission?.rolemanagement?.update ||
        permission?.userlistmanagement?.read ||
        permission?.userlistmanagement?.create ||
        permission?.userlistmanagement?.delete ||
        permission?.userlistmanagement?.update ||
        permission?.permissionmanagement?.read ||
        permission?.permissionmanagement?.create ||
        permission?.permissionmanagement?.delete ||
        permission?.permissionmanagement?.update ||
        currentUser?.role === 'client-store-owner') && (
        <SidebarMenuItemWithSub to='/user-management' icon='user-edit' title='User Management'>
          {(permission?.rolemanagement?.read ||
            permission?.rolemanagement?.create ||
            permission?.rolemanagement?.delete ||
            permission?.rolemanagement?.update ||
            currentUser?.role === 'client-store-owner') && (
            <SidebarMenuItem to='/user-management/roles-list' hasBullet title='Roles List' />
          )}
          {(permission?.userlistmanagement?.read ||
            permission?.userlistmanagement?.create ||
            permission?.userlistmanagement?.delete ||
            permission?.userlistmanagement?.update ||
            currentUser?.role === 'client-store-owner') && (
            <SidebarMenuItem to='/user-management/users-list' hasBullet title='User List' />
          )}
          {(permission?.permissionmanagement?.read ||
            permission?.permissionmanagement?.create ||
            permission?.permissionmanagement?.delete ||
            permission?.permissionmanagement?.update ||
            currentUser?.role === 'client-store-owner') && (
            <SidebarMenuItem
              to='/user-management/permissions-list'
              hasBullet
              title='Permissions List'
            />
          )}
        </SidebarMenuItemWithSub>
      )}

      {/* Notifications */}
      {(permission?.notificationmanagement?.read ||
        permission?.notificationmanagement?.create ||
        permission?.notificationmanagement?.delete ||
        permission?.notificationmanagement?.update ||
        currentUser?.role === 'client-store-owner') && (
        <SidebarMenuItem to='/notifications' icon='notification-bing' title='Notifications' />
      )}

      {currentUser?.subscription === 'free' && currentUser?.role === 'client-store-owner' && (
        <div className='upgrade-plan'>
          <div className='upgrade-plan--inner'>
            <h3 className='' style={{color: 'white', marginBottom: '10px'}}>
              Upgrade Plan
            </h3>
            <p style={{color: 'white'}}>
              You have <span className='text-danger'>5 Invoices & Receipts</span> left this month.
              Upgrade to Enterprise Plan to get unlimited access.
            </p>

            <div className='footer'>
              <Link to='/settings/plan-settings' className='btn btn-sm btn-color-primary'>
                Upgrade
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export {SidebarMenuCustom}
