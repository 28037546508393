import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik, Field} from 'formik'
import OTPInput from 'react-otp-input'

import {requestPassword} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import LanguageSelector from '../../../../components/shared/LanguageSelector'

const initialValues = {
  otp: '',
}

const forgotPasswordSchema = Yup.object().shape({
  otp: Yup.string().required('This is required'),
})

export function ConfirmOTP() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)

  const [otp, setOtp] = useState('')

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      console.log(values)
    },
  })

  return (
    <>
      <div className='d-flex flex-stack py-2'>
        <div className='me-2'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/pbv.png')} className='h-100px mb-6' />
        </div>
      </div>

      <div className='py-20'>
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_password_reset_form'
          onSubmit={formik.handleSubmit}
        >
          <div className='text-start mb-11'>
            <h1 className='text-dark fw-bolder mb-3'>Enter Verification Code</h1>
            <div className='text-gray-500 fw-semibold fs-6'>
              Enter the verficiation code that we've sent to your email.
            </div>
          </div>

          {hasErrors === true && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>
                Sorry, looks like there are some errors detected, please try again.
              </div>
            </div>
          )}

          {hasErrors === false && (
            <div className='mb-10 bg-light-info p-8 rounded'>
              <div className='text-info'>Sent password reset. Please check your email</div>
            </div>
          )}

          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>Verification Code</label>

            <OTPInput
              value={otp}
              onChange={(e) => {
                setOtp(e)
                formik.setFieldValue('otp', e)
              }}
              numInputs={6}
              renderInput={(props) => (
                <input {...props} className={clsx('mx-1 form-control-solid')} />
              )}
              inputStyle={{
                display: 'block',
                width: ' 100%',
                padding: '0.775rem 1rem',
                fontSize: '1.1rem',
                fontWeight: '500',
                lineHeight: '1.5',
                color: 'var(--bs-gray-700)',
                backgroundColor: 'var(--bs-gray-100)',
                backgroundClip: 'padding-box',
                border: '1px solid transparent',
                appearance: 'none',
                borderRadius: '0.475rem',
                boxShadow: 'false',
                transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
              }}
              containerStyle={{
                width: '100%',
              }}
            />
            {formik.touched.otp && formik.errors.otp && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.otp}</span>
                </div>
              </div>
            )}
          </div>

          <div className='pb-lg-0 d-flex flex-start gap-4'>
            <button type='submit' id='kt_password_reset_submit' className='btn btn-primary'>
              <span className='indicator-label'>Continue</span>
              {loading && (
                <span className='indicator-progress'>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <Link to='/auth/login'>
              <button
                type='button'
                id='kt_login_password_reset_form_cancel_button'
                className='btn btn-light text-primary'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                Return to Log in
              </button>
            </Link>{' '}
          </div>
        </form>
      </div>

      <div />
    </>
  )
}
